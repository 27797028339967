/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

/*****************
 * 問い合わせリクエスト
 *****************/

const RECEIVE_CONTACT_FORM_MESSAGE = "RECEIVE_CONTACT_FORM_MESSAGE";

const SEND_CONTACT_FORM = "SEND_CONTACT_FORM";
const SEND_CONTACT_FORM_LOADING = "SEND_CONTACT_FORM_LOADING";
const SEND_CONTACT_FORM_SUCCESS = "SEND_CONTACT_FORM_SUCCESS";
const SEND_CONTACT_FORM_FAILURE = "SEND_CONTACT_FORM_FAILURE";

const types = {
    RECEIVE_CONTACT_FORM_MESSAGE,

    SEND_CONTACT_FORM,
    SEND_CONTACT_FORM_LOADING,
    SEND_CONTACT_FORM_SUCCESS,
    SEND_CONTACT_FORM_FAILURE
};

export default types;
