/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（serviceContracts）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const serviceContractTransferSagas = Object.values(watchersSagas);
export {default as serviceContractTransferSelectors} from "./selectors";
export {default as serviceContractTransferOperations} from "./operations";
export {default as serviceContractTransferTypes} from "./types";
export {default as serviceContractTransferForms} from "./forms"

export {serviceContractTransferState, default as serviceContractTransferReducer} from './reducers';
