/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（serviceContracts）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const fetchServiceContractTerminateSagas = Object.values(watchersSagas);
export {default as serviceContractTerminateSelectors} from "./selectors";
export {default as serviceContractTerminateOperations} from "./operations";
export {default as serviceContractTerminateTypes} from "./types";
export {default as serviceContractTerminateForms} from "./forms"

export {serviceContractTerminateState, default as serviceContractTerminateReducer} from './reducers';
