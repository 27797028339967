import {takeEvery, takeLeading} from "redux-saga/effects";
import {default as types} from "./types";
import {
    managerAddNoticeSaga,
    managerDeleteNotify,
    managerNotificationsSearchAccount,
    managerSelectNoticeList,
    managerUpdateNoticeSaga
} from "./sagas";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */


export function* fetchNotices() {
    yield takeLeading(types.MANAGER_REQUEST_NOTICES, managerSelectNoticeList);
    yield takeLeading(types.MANAGER_ADD_NOTICE, managerAddNoticeSaga);
    yield takeEvery(types.MANAGER_UPDATE_NOTICE, managerUpdateNoticeSaga);
    yield takeLeading(types.MANAGER_DELETE_NOTICE_REQUEST, managerDeleteNotify);
    yield takeLeading(types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD, managerNotificationsSearchAccount)
}
