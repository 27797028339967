/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
import {takeLeading} from "redux-saga/effects";
import {confirmPlanChangesRequest, serviceContractAllPlansRequest, serviceContractContentsRequest} from "./sagas";
import types from "./types"

export function* fetchServiceContractContents() {
    yield takeLeading(types.SERVICE_CONTRACT_CONTENT, serviceContractContentsRequest)
    yield takeLeading(types.SERVICE_CONTRACT_CONTENT_ALL_PLAN, serviceContractAllPlansRequest)
    yield takeLeading(types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED, confirmPlanChangesRequest)
}
