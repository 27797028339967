import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

/**
 * 問い合わせフォームを受け取るアクション
 * @param form
 * @returns {{payload: {message}, type: string}}
 */
export function receiveContactFormMessage(form) {
    return {
        type: types.RECEIVE_CONTACT_FORM_MESSAGE,
        payload: {
            message: form.message
        }
    };
}

/**********
 * 問い合わせフォーム送信アクション
 **********/
export function confirmContactRequest(accessToken, accountId, message, callBackAction) {
    return {
        type: types.SEND_CONTACT_FORM,
        payload: {
            accessToken: accessToken,
            accountId: accountId,
            message: message
        },
        meta: {
            callBackAction: callBackAction
        }
    }
}

export function confirmContactRequestLoading() {
    return {
        type: types.SEND_CONTACT_FORM_LOADING
    }
}

export function confirmContactRequestSuccess() {
    return {
        type: types.SEND_CONTACT_FORM_SUCCESS
    }
}

export function confirmContactRequestFailure(e) {
    return {
        type: types.SEND_CONTACT_FORM_FAILURE,
        payload: {
            error: e
        }
    }
}


const actions = {
    receiveContactFormMessage,

    confirmContactRequest,
    confirmContactRequestLoading,
    confirmContactRequestSuccess,
    confirmContactRequestFailure
};

export default actions;
