import actions from "./actions";
/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

const approveTransferRequest = actions.approveTransferRequest
const approveTransferRequestSend = actions.approveTransferRequestSend
const operations = {
    approveTransferRequest,
    approveTransferRequestSend
};
export default operations;
