import {colors, createMuiTheme} from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import overrides from "./overrides";
import props from "./props";

/**
 * Material Theme UI - 管理者側レイアウトのデフォルトスタイルガイド
 * 詳細はこちら : https://material-ui.com/customization/palette/
 *
 * @type {Theme}
 */
const theme = createMuiTheme({
    palette: {
        // 背景色
        background: {
            dark: '#F2F2F2',
            active: '#CCCCCC',
            required: '#F4B069',
            default: colors.common.white,
            paper: colors.common.white,
            navHeader: "#000000"
        },
        // 主なカラー
        // * Contrast text = 反対側のテキスト・背景色で見せる
        primary: {
            main: "#EF7C04",
            contrastText: "#fff"
        },
        secondary: {
            main: "#FFFFFF"
        },
        warning: {
            main: "#000000"
        },
        text: {
            primary: "#000",
            secondary: "#FFFFFFFF",
            navText: '#000000'
        }
    },
    // テーマの影
    shadows,
    // テーマのタイポグラフィ
    typography,
    // テーマコンポーネントのスタイルの上書き
    overrides,
    // コンポーネント属性の上書き
    props
});

export default theme;
