/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const MANAGER_REQUESTS_TOGGLE_SEARCH = "MANAGER_REQUESTS_TOGGLE_SEARCH";

export const MANAGER_REQUESTS_SEARCH = "MANAGER_REQUESTS_SEARCH";
export const MANAGER_REQUESTS_SEARCH_LOADING = "MANAGER_REQUESTS_SEARCH_LOADING";
export const MANAGER_REQUESTS_SEARCH_SUCCESS = "MANAGER_REQUESTS_SEARCH_SUCCESS";
export const MANAGER_REQUESTS_SEARCH_FAILED = "MANAGER_REQUESTS_SEARCH_FAILED";


export const MANAGER_REQUESTS_LOAD = "MANAGER_REQUESTS_LOAD";
export const MANAGER_REQUESTS_LOADING = "MANAGER_REQUESTS_LOADING";
export const MANAGER_REQUESTS_SUCCESS = "MANAGER_REQUESTS_SUCCESS";
export const MANAGER_REQUESTS_FAILED = "MANAGER_REQUESTS_FAILED";

export const MANAGER_EDIT_SEARCH_FORM = "MANAGER_EDIT_SEARCH_FORM";

// 一覧ページ変更
export const MANAGER_CHANGE_REQUESTS_PAGE = "MANAGER_CHANGE_REQUESTS_PAGE";
// ダウンロードエベント
export const MANAGER_REQUESTS_LIST_DOWNLOAD = "MANAGER_REQUESTS_LIST_DOWNLOAD"
export const MANAGER_REQUESTS_LIST_DOWNLOAD_LOADING = "MANAGER_REQUESTS_LIST_DOWNLOAD_LOADING"
export const MANAGER_REQUESTS_LIST_DOWNLOAD_SUCCESS = "MANAGER_REQUESTS_LIST_DOWNLOAD_SUCCESS"
export const MANAGER_REQUESTS_LIST_DOWNLOAD_FAILURE = "MANAGER_REQUESTS_LIST_DOWNLOAD_FAILURE"

/** お申込詳細 **/
export const MANAGER_REQUESTS_DETAIL_LOAD = "MANAGER_REQUESTS_DETAIL_LOAD";
export const MANAGER_REQUESTS_DETAIL_LOAD_LOADING = "MANAGER_REQUESTS_DETAIL_LOAD_LOADING";
export const MANAGER_REQUESTS_DETAIL_LOAD_SUCCESS = "MANAGER_REQUESTS_DETAIL_LOAD_SUCCESS";
export const MANAGER_REQUESTS_DETAIL_LOAD_FAILURE = "MANAGER_REQUESTS_DETAIL_LOAD_FAILURE";

/** お申込の見積詳細 **/
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD";
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING";
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS";
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE";

export const MANAGER_OPEN_TERMS_AND_CONDITIONS_MODAL = "MANAGER_OPEN_TERMS_AND_CONDITIONS_MODAL";
export const MANAGER_CLOSE_TERMS_AND_CONDITIONS_MODAL = "MANAGER_CLOSE_TERMS_AND_CONDITIONS_MODAL";

export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF";
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING";
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS";
export const MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE = "MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE";

export const MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE = "MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE";

export const MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST = "MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST"
export const MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_LOADING = "MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_LOADING"
export const MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS = "MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS"
export const MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE = "MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE"

export const MANAGER_APPROVE_TERMINATE_CONTRACT_MODAL_TOGGLE = "MANAGER_APPROVE_TERMINATE_CONTRACT_MODAL_TOGGLE";

export const MANAGER_APPROVE_TERMINATE_CONTRACT = "MANAGER_APPROVE_TERMINATE_CONTRACT"
export const MANAGER_APPROVE_TERMINATE_CONTRACT_LOADING = "MANAGER_APPROVE_TERMINATE_CONTRACT_LOADING"
export const MANAGER_APPROVE_TERMINATE_CONTRACT_SUCCESS = "MANAGER_APPROVE_TERMINATE_CONTRACT_SUCCESS"
export const MANAGER_APPROVE_TERMINATE_CONTRACT_FAILURE = "MANAGER_APPROVE_TERMINATE_CONTRACT_FAILURE"

export const MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY = "MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY"
export const MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_LOADING = "MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_LOADING"
export const MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_SUCCESS = "MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_SUCCESS"
export const MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_FAILURE = "MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_FAILURE"

export const MANAGER_ACCOUNT_SET_ACCOUNT_NAME_TO_FORM = "MANAGER_ACCOUNT_SET_ACCOUNT_NAME_TO_FORM"

const types = {
    MANAGER_REQUESTS_TOGGLE_SEARCH,
    MANAGER_REQUESTS_SEARCH,

    MANAGER_REQUESTS_SEARCH_LOADING,
    MANAGER_REQUESTS_SEARCH_SUCCESS,
    MANAGER_REQUESTS_SEARCH_FAILED,

    MANAGER_REQUESTS_LOAD,
    MANAGER_REQUESTS_LOADING,
    MANAGER_REQUESTS_SUCCESS,
    MANAGER_REQUESTS_FAILED,

    MANAGER_EDIT_SEARCH_FORM,

    MANAGER_CHANGE_REQUESTS_PAGE,

    MANAGER_REQUESTS_LIST_DOWNLOAD,
    MANAGER_REQUESTS_LIST_DOWNLOAD_LOADING,
    MANAGER_REQUESTS_LIST_DOWNLOAD_SUCCESS,
    MANAGER_REQUESTS_LIST_DOWNLOAD_FAILURE,

    MANAGER_REQUESTS_DETAIL_LOAD,
    MANAGER_REQUESTS_DETAIL_LOAD_LOADING,
    MANAGER_REQUESTS_DETAIL_LOAD_SUCCESS,
    MANAGER_REQUESTS_DETAIL_LOAD_FAILURE,

    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD,
    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING,
    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS,
    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE,

    MANAGER_OPEN_TERMS_AND_CONDITIONS_MODAL,
    MANAGER_CLOSE_TERMS_AND_CONDITIONS_MODAL,

    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF,
    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING,
    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS,
    MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE,

    MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE,
    MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST,
    MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_LOADING,
    MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS,
    MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE,

    MANAGER_APPROVE_TERMINATE_CONTRACT_MODAL_TOGGLE,

    MANAGER_APPROVE_TERMINATE_CONTRACT,
    MANAGER_APPROVE_TERMINATE_CONTRACT_LOADING,
    MANAGER_APPROVE_TERMINATE_CONTRACT_SUCCESS,
    MANAGER_APPROVE_TERMINATE_CONTRACT_FAILURE,

    MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY,
    MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_LOADING,
    MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_SUCCESS,
    MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_FAILURE,

    MANAGER_ACCOUNT_SET_ACCOUNT_NAME_TO_FORM
};

export default types;
