/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

import {
    approveTransferRequestFailure,
    approveTransferRequestLoading,
    approveTransferRequestSendLoading, approveTransferRequestSendSuccess,
    approveTransferRequestSuccess
} from "./actions";
import {call, put} from "redux-saga/effects";
import {getTransferAcceptAPIPath, sendTransferAcceptAPIPath} from "./api";
import {error_raise} from "../../root/actions";

/**
 * @param data
 */
export function* transferApproveGet(data) {
    yield put(approveTransferRequestLoading())
    try {
        const apiData = yield call(getTransferAcceptAPIPath, data.payload.account_id);
        yield put(approveTransferRequestSuccess(apiData.data));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(approveTransferRequestFailure(e));
    }
}

/**
 * @param data
 */
export function* transferApproveSend(data) {
    yield put(approveTransferRequestSendLoading())
    try {
        yield call(sendTransferAcceptAPIPath, data.payload.account_id);
        yield put(approveTransferRequestSendSuccess());
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(approveTransferRequestFailure(e));
    }
}

