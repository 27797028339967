import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

const serviceContractContentLoad = actions.serviceContractContentLoad;
const serviceContractContentAllPlansLoad = actions.serviceContractContentAllPlansLoad;
const contractUpdateToConfirmPage = actions.contractUpdateToConfirmPage;
const contractBackToUpdatePage = actions.contractBackToUpdatePage;
const resetForm = actions.resetForm;
const confirmContractUpdate = actions.confirmContractUpdate;

const addPlan = actions.addPlan;
const changeItem = actions.changeItem;
const changePlan = actions.changePlan;
const removePlan = actions.removePlan;
const undoPlanUpdate = actions.undoPlanUpdate;
const undoBasicPlanUpdate = actions.undoBasicPlanUpdate
const modifyNotes = actions.modifyNotes
const toggleTerms = actions.toggleTerms

const operations = {
    serviceContractContentLoad,
    serviceContractContentAllPlansLoad,
    contractUpdateToConfirmPage,
    contractBackToUpdatePage,
    resetForm,
    confirmContractUpdate,

    addPlan,
    changeItem,
    changePlan,
    removePlan,
    undoPlanUpdate,
    undoBasicPlanUpdate,
    modifyNotes,
    toggleTerms
}
export default operations;
