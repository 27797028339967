import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../state/ducks/root";
import userSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {notificationListOperations} from "../../../../state/ducks/user/notifications";
import NotificationView from "../../../components/user/notifications/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        loading: state.notification.loading,
        notifications: state.notification.notifications,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    loadNotificationList: notificationListOperations.loadNotificationList,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _notificationsContainer = (
    {
        managerProfile,
        loadSidebarItems,
        loadNotificationList,

        loading,
        notifications,
        requestList,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadNotificationList(access_token, managerProfile?.account?.uuid)
            })()
        }
    }, [getAccessTokenSilently, loadNotificationList, managerProfile])

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.notifications)
    }, [loadSidebarItems])

    return (
        <NotificationView
            loading={loading}
            profile={managerProfile}
            t={t}
            loadNotificationList={loadNotificationList}
            notifications={notifications}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const NotificationsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_notificationsContainer)

export default NotificationsContainer;

