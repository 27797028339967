import React from 'react';
import {Box, Breadcrumbs, Button, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import Validation, {translate} from "../../../../state/utils/Validation";
import {Field, Form} from "react-final-form";
import FinalFormTextArea from "../../../atoms/FinalFormTextArea";
import {contactFormValidation} from "../../../../state/ducks/user/contact/validation";
import {mergeWithDefaultForm} from "../../../../state/utils/Common";
import {contactForms} from "../../../../state/ducks/user/contact";
import {useHistory} from "react-router";
import EditableCheck from "../../../atoms/EditableCheck";
import {Link} from "react-router-dom";
import urlLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @param receiveContactFormMessage
 * @returns {JSX.Element}
 * @constructor
 */
const ContactPage = (
    {
        t,
        message,
        receiveContactFormMessage
    }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Page
            className={classes.root}
            title={t('contact.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">{t('contact.title')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 11}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.contact.index.href}>
                                <Typography color="textPrimary">{t('contact.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography variant={"h4"}>{t('contact.contact_form')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Typography style={{marginBottom: 20}}
                                        variant={"body1"}>{t('contact.description')}</Typography>
                            <Form
                                onSubmit={(e) => {
                                    e = mergeWithDefaultForm(e, contactForms.createContactForm);
                                    receiveContactFormMessage(e);
                                    history.push('/contact/confirm');
                                }}
                                // ここでフォームデータを妥当性確認し、キーを変換します。
                                validate={e => {
                                    return translate(t, Validation({...e}, contactFormValidation))
                                }}
                                // （編集モード）最初のバリュー
                                initialValues={{
                                    message: message
                                }}
                                // ここでは、フォームのレンダリングと制御を行います
                                // エラー処理やダブルクリック防止などはここで行います
                                render={({handleSubmit, form, pristine, invalid, values}) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Box paddingBottom={5}>
                                            <Grid container>
                                                <Grid item xs={12} sm={7} md={5}>
                                                    <Field
                                                        fullWidth
                                                        name="message"
                                                        required={true}
                                                        rows={4}
                                                        component={FinalFormTextArea}
                                                        type="text"
                                                        label={t('contact.contact_form_contents')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box paddingBottom={5}>
                                            <Grid container justify={"center"} alignItems={"center"}
                                                  alignContent={"center"}>
                                                <Grid item xs={12} style={{textAlign: "center"}}>
                                                    <EditableCheck>
                                                        <Button disabled={invalid} type={"submit"} variant={"contained"}
                                                                color={"primary"}>
                                                            {t('actions.confirm')}
                                                        </Button>
                                                    </EditableCheck>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>)}
                            />
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default ContactPage;
