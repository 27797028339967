import {call, put} from "redux-saga/effects";
import {error_raise} from "../../../root/actions";
import {
    getTransferDataFailed,
    getTransferDataLoading,
    getTransferDataSuccess,
    sendTransferRequestFailure,
    sendTransferRequestLoading,
    sendTransferRequestSuccess
} from "./actions";
import {updateServiceContractApi} from "./api";
import {getCancelServiceReasonsAPI} from "../terminate/api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * 利用契約の連絡先取得リクエスト
 * @param data
 */
export function* sendTransferRequestSaga(data) {
    yield put(sendTransferRequestLoading())
    try {
        yield call(updateServiceContractApi, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId, data.payload.form);
        yield call(data.meta.callBackAction)
        yield put(sendTransferRequestSuccess());
    } catch (e) {
        yield put(sendTransferRequestFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}


/**
 * 契約移管データの取得
 * @param data
 * */
export function* getTransferDataSaga(data) {
    yield put(getTransferDataLoading())
    try {
        const cancelReasons = yield call(getCancelServiceReasonsAPI, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId);
        yield put(getTransferDataSuccess(cancelReasons?.data));
    } catch (e) {
        yield put(getTransferDataFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
