import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import NoticesView from "../../../components/manager/notices/Page";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../state/ducks/root";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json"
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {managerNoticeOperations} from "../../../../state/ducks/manager/managerNotices";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        managerLoading: state.managerNotice.managerLoading,
        managerNotices: state.managerNotice.managerNotices,

        managerIsDeleteView: state.managerNotice.managerIsDeleteView,
        deleteNoticeUUID: state.managerNotice.deleteNoticeUUID,
        deleteLoading: state.managerNotice.deleteLoading,
        deleteError: state.managerNotice.deleteError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    managerLoadNoticeList: managerNoticeOperations.managerLoadNoticeList,
    managerNotificationRegisterToggle: managerNoticeOperations.managerNotificationRegisterToggle,
    managerViewNoticeDeleteToggle: managerNoticeOperations.managerViewNoticeDeleteToggle,
    managerDeleteNotice: managerNoticeOperations.managerDeleteNotice,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
利用契約 -> 連絡先コンテイナ
 */
const _managerNoticeContainer = (
    {
        loadSidebarItems,
        managerProfile,

        managerLoadNoticeList,
        managerLoading,
        managerNotices,

        managerNotificationRegisterToggle,

        managerViewNoticeDeleteToggle,
        managerIsDeleteView,
        deleteLoading,
        deleteError,
        deleteNoticeUUID,
        managerDeleteNotice,

        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                managerLoadNoticeList(access_token)
            })()
        }
    }, [getAccessTokenSilently, managerLoadNoticeList, managerProfile])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.notices)
    }, [loadSidebarItems])
    return (
        <NoticesView
            managerLoading={managerLoading}
            managerNotices={managerNotices}
            managerProfile={managerProfile}
            managerNotificationRegisterToggle={managerNotificationRegisterToggle}
            managerViewNoticeDeleteToggle={managerViewNoticeDeleteToggle}
            managerIsDeleteView={managerIsDeleteView}
            deleteLoading={deleteLoading}
            deleteError={deleteError}
            deleteNoticeUUID={deleteNoticeUUID}
            managerDeleteNotice={managerDeleteNotice}
            t={t}
        />
    )
}


/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerNoticeContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerNoticeContainer)

export default ManagerNoticeContainer;
