/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

// 一覧取得
export const MANAGER_REQUEST_USERS = 'MANAGER_REQUEST_USERS';
export const MANAGER_REQUEST_USERS_LOADING = 'MANAGER_REQUEST_USERS_LOADING';
export const MANAGER_REQUEST_USERS_SUCCESS = 'MANAGER_REQUEST_USERS_SUCCESS';
export const MANAGER_REQUEST_USERS_FAILURE = 'MANAGER_REQUEST_USERS_FAILURE';
export const MANAGER_CHANGE_PAGE = 'MANAGER_CHANGE_PAGE'

// モーダル開閉表示
export const MANAGER_TOGGLE_REGISTER = 'MANAGER_TOGGLE_REGISTER';
export const MANAGER_REGISTER_LOADING = 'MANAGER_REGISTER_LOADING';

// 新規ユーザー
export const MANAGER_ADD_USER = 'MANAGER_ADD_USER';
export const MANAGER_ADD_USER_SUCCESS = 'MANAGER_ADD_USER_SUCCESS';
export const MANAGER_ADD_USER_FAILURE = 'MANAGER_ADD_USER_FAILURE';

// 検索
export const MANAGER_SEARCH_USER_REQUEST = 'MANAGER_SEARCH_USER_REQUEST';
export const MANAGER_SEARCH_USER_LOADING = 'MANAGER_SEARCH_USER_LOADING';
export const MANAGER_SEARCH_USER_SUCCESS = 'MANAGER_SEARCH_USER_SUCCESS';
export const MANAGER_SEARCH_USER_FAILURE = 'MANAGER_SEARCH_USER_FAILURE';

// モーダル開閉表示
export const MANAGER_TOGGLE_USER_DELETE = 'MANAGER_TOGGLE_USER_DELETE';

// 削除
export const MANAGER_DELETE_USER_REQUEST = 'MANAGER_DELETE_USER_REQUEST';
export const MANAGER_DELETE_USER_LOADING = "MANAGER_DELETE_USER_LOADING";
export const MANAGER_DELETE_USER_SUCCESS = "MANAGER_DELETE_USER_SUCCESS";
export const MANAGER_DELETE_USER_FAILURE = "MANAGER_DELETE_USER_FAILURE";

// 編集
export const MANAGER_UPDATE_USER_REQUEST = "MANAGER_UPDATE_USER_REQUEST";

export const MANAGER_AUTH0_UNLOCK_USER = "MANAGER_AUTH0_UNLOCK_USER";
export const MANAGER_AUTH0_UNLOCK_USER_LOADING = "MANAGER_AUTH0_UNLOCK_USER_LOADING";
export const MANAGER_AUTH0_UNLOCK_USER_SUCCESS = "MANAGER_AUTH0_UNLOCK_USER_SUCCESS";
export const MANAGER_AUTH0_UNLOCK_USER_FAILED = "MANAGER_AUTH0_UNLOCK_USER_FAILED";
export const MANAGER_AUTH0_UNLOCK_USER_CLOSE_MODAL = "MANAGER_AUTH0_UNLOCK_USER_CLOSE_MODAL";

export const MANAGER_AUTH0_RESEND_VERIFY_EMAIL = "MANAGER_AUTH0_RESEND_VERIFY_EMAIL";
export const MANAGER_AUTH0_RESEND_VERIFY_EMAIL_LOADING = "MANAGER_AUTH0_RESEND_VERIFY_EMAIL_LOADING";
export const MANAGER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS = "MANAGER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS";
export const MANAGER_AUTH0_RESEND_VERIFY_EMAIL_FAILED = "MANAGER_AUTH0_RESEND_VERIFY_EMAIL_FAILED";
export const MANAGER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL = "MANAGER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL";

const types = {
    MANAGER_REQUEST_USERS,
    MANAGER_REQUEST_USERS_LOADING,
    MANAGER_REQUEST_USERS_SUCCESS,
    MANAGER_REQUEST_USERS_FAILURE,
    MANAGER_CHANGE_PAGE,

    MANAGER_TOGGLE_REGISTER,
    MANAGER_REGISTER_LOADING,

    MANAGER_ADD_USER,
    MANAGER_ADD_USER_SUCCESS,
    MANAGER_ADD_USER_FAILURE,

    MANAGER_SEARCH_USER_REQUEST,
    MANAGER_SEARCH_USER_LOADING,
    MANAGER_SEARCH_USER_SUCCESS,
    MANAGER_SEARCH_USER_FAILURE,

    MANAGER_TOGGLE_USER_DELETE,
    MANAGER_DELETE_USER_LOADING,
    MANAGER_DELETE_USER_REQUEST,
    MANAGER_DELETE_USER_SUCCESS,
    MANAGER_DELETE_USER_FAILURE,

    MANAGER_UPDATE_USER_REQUEST,

    MANAGER_AUTH0_UNLOCK_USER,
    MANAGER_AUTH0_UNLOCK_USER_LOADING,
    MANAGER_AUTH0_UNLOCK_USER_SUCCESS,
    MANAGER_AUTH0_UNLOCK_USER_FAILED,
    MANAGER_AUTH0_UNLOCK_USER_CLOSE_MODAL,

    MANAGER_AUTH0_RESEND_VERIFY_EMAIL,
    MANAGER_AUTH0_RESEND_VERIFY_EMAIL_LOADING,
    MANAGER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS,
    MANAGER_AUTH0_RESEND_VERIFY_EMAIL_FAILED,
    MANAGER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL,
};
export default types;
