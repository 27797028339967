/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

const searchForm = {
    account_name: null,
    ebis_client_name: null,
    application_at_from: null,
    application_at_to: null,
    status: null,
    type: null,
    application_complete_at_from: null,
    application_complete_at_to: null,
    reflect_at_from: null,
    reflect_at_to: null
}

const forms = {
    searchForm
}

export default forms
