import React from 'react'
import TextField from "@material-ui/core/TextField";
import {Chip, CircularProgress, FormLabel} from "@material-ui/core";
import globalTheme from '../theme/globalTheme'
import {Autocomplete} from "@material-ui/lab";
import {empty} from "../../state/utils/Common";

/**
 * Final FormプラグインとMaterial UI Formフィールドを結合するコンポーネント
 * final formの入力フェルド
 *
 * @param {string} name - テキストフィールドの名前
 * @param {*} onChange - テキスト変更イベント
 * @param {string} value - テキスト値
 * @param {number} multiline - テキストエリアの行数
 * @param {*} restInput - 他の属性
 * @param {Object} meta - final formメタ情報
 * @param loadingOnDisable - ロード中の状態
 * @param additionalOnChangeEvent
 * @param styleOverride
 * @param {*} rest - 他の属性
 * @returns {JSX.Element}
 */
const FinalFormAutoSuggest = (
    {
        input: {name, onChange, value, multiline = 0, ...restInput},
        meta,
        loadingOnDisable,
        additionalOnChangeEvent,
        styleOverride,
        ...rest
    }) => {
    const classes = globalTheme.defaultFormStyle();
    const loadOnDisable = loadingOnDisable && rest.disabled ? classes.loadingOnDisable : undefined
    let style = {};

    let rootOverride = {}

    if (!empty(styleOverride)) {
        rootOverride = styleOverride
    }

    if (rest?.noBold === true) {
        style = {fontWeight: 300}
    }

    return (
        <div style={rootOverride}>
            <FormLabel className={loadOnDisable} style={style}>
                {rest.label}
                {rest.required &&
                <Chip label={"必須"} size="small" className={[classes.chip, loadOnDisable].join(" ")}/>
                }
            </FormLabel>
            <Autocomplete
                autoComplete={true}
                className={[classes.textfield, loadOnDisable].join(" ")}
                {...rest}
                name={name}
                data-testid={name}
                disableClearable
                renderInput={(params) => (
                    <TextField {...params}
                               variant="outlined"
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                       <React.Fragment>
                                           {rest.loading ? <CircularProgress color="inherit" size={20}/> : null}
                                           {params.InputProps.endAdornment}
                                       </React.Fragment>
                                   ),
                               }}
                               helperText={meta.error}
                               error={meta.error && meta.touched}
                    />
                )}
                getOptionLabel={option => option?.name}
                onKeyPress={e => {
                    e.key === "Enter" && e.preventDefault()
                }}
                disabled={rest.disabled}
                rows={multiline}
                noOptionsText={"選択してくだいさい。"}
                onChange={(event, value, reason, details) => {
                    onChange(value ? value.id : null)
                }}
                // CARE
                value={rest.options.find((option) => option.id === value) || null}
                label=""
            />
        </div>)
}
export default FinalFormAutoSuggest;
