import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {managerCommonStyles} from "../../../../theme/manager/styles/common";
import {FixedSizeList} from "react-window";
import {NoticesListRow} from "./NoticesListRow";
import AutoSizer from "react-virtualized-auto-sizer";
import globalTheme from "../../../../theme/globalTheme";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
/**
 * 管理者側の通知一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param {Object} notices - ロードされたお知らせ
 * @param {number|string} loginUserID - ログインしているユーザーID
 * @param {managerLoadingUserList} managerLoading - "一覧が読み込まれていますか？"状態
 * @param managerNotificationRegisterToggle
 * @param managerViewNoticeDeleteToggle
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const NoticesList = ({
                         className,
                         notices,
                         loginUserID,
                         managerLoading,
                         managerNotificationRegisterToggle,
                         managerViewNoticeDeleteToggle,
                         t
                     }) => {
    const classes = useStyles();
    const common = managerCommonStyles();
    const _globalTheme = globalTheme._default();

    // ユーザー一覧の編集選択アクション
    function handleEditClick(id) {
        managerNotificationRegisterToggle(id)
    }

    // ユーザー一覧の削除選択
    function handleDeleteClick(id) {
        managerViewNoticeDeleteToggle(id)
    }

    return (
        <Card
            className={clsx(classes.root, className)}
        >
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{fontWeight: 900}}
                        >
                            {t('notices.list')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{'textAlign': 'right'}}
                        >
                            {t('general.list_amount', {amount: !empty(notices) ? notices.length : 0})}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container className={common.tableHeader} style={{paddingRight: 25}}>
                <Grid item xs={3}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('notices.list_headers.title')}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('notices.list_headers.public_account')}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('notices.list_headers.public_duration')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('notices.list_headers.edit')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('notices.list_headers.delete')}
                    </Typography>
                </Grid>
            </Grid>
            {managerLoading === true ?
                <Box className={_globalTheme.list} display="flex" alignItems="center" data-testid={"loading"}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </Box>
                : !empty(notices) &&
                <Box className={_globalTheme.list} data-testid={"has-users"}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={notices.length}
                                itemSize={45}
                                itemData={{
                                    notices: notices,
                                    loginUserID: loginUserID,
                                    t: t,
                                    handleEditClick: (id) => handleEditClick(id),
                                    handleDeleteClick: (id) => handleDeleteClick(id)
                                }}
                            >
                                {NoticesListRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            }
        </Card>
    );
};

export default NoticesList;
