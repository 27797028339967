/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

const SERVICE_CONTRACT_CONTENT = "SERVICE_CONTRACT_CONTENT";
const SERVICE_CONTRACT_CONTENT_LOADING = "SERVICE_CONTRACT_CONTENT_LOADING";
const SERVICE_CONTRACT_CONTENT_SUCCESS = "SERVICE_CONTRACT_CONTENT_SUCCESS";
const SERVICE_CONTRACT_CONTENT_FAILURE = "SERVICE_CONTRACT_CONTENT_FAILURE";

const SERVICE_CONTRACT_CONTENT_ALL_PLAN = "SERVICE_CONTRACT_CONTENT_ALL_PLAN";
const SERVICE_CONTRACT_CONTENT_ALL_PLAN_LOADING = "SERVICE_CONTRACT_CONTENT_ALL_PLAN_LOADING";
const SERVICE_CONTRACT_CONTENT_ALL_PLAN_SUCCESS = "SERVICE_CONTRACT_CONTENT_ALL_PLAN_SUCCESS";
const SERVICE_CONTRACT_CONTENT_ALL_PLAN_FAILURE = "SERVICE_CONTRACT_CONTENT_ALL_PLAN_FAILURE";

const SERVICE_CONTRACT_CONTENT_UPDATE_TO_CONFIRM = "SERVICE_CONTRACT_CONTENT_UPDATE_TO_CONFIRM";
const SERVICE_CONTRACT_CONTENT_BACK_TO_UPDATE = "SERVICE_CONTRACT_CONTENT_BACK_TO_UPDATE";
const SERVICE_CONTRACT_CONTENT_RESET_UPDATE_FORM = "SERVICE_CONTRACT_CONTENT_RESET_UPDATE_FORM";

const SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED = "SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED";
const SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_LOADING = "SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_LOADING";
const SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_SUCCESS = "SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_SUCCESS";
const SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_FAILURE = "SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_FAILURE";

const SERVICE_CONTRACT_CONTENT_ADD_PLAN = "SERVICE_CONTRACT_CONTENT_ADD_PLAN";
const SERVICE_CONTRACT_CONTENT_REMOVE_PLAN = "SERVICE_CONTRACT_CONTENT_REMOVE_PLAN";
const SERVICE_CONTRACT_CONTENT_UPDATE_ITEM = "SERVICE_CONTRACT_CONTENT_UPDATE_ITEM";
const SERVICE_CONTRACT_CONTENT_UPDATE_PLAN = "SERVICE_CONTRACT_CONTENT_UPDATE_PLAN";
const SERVICE_CONTRACT_CONTENT_UNDO_UPDATE_PLAN = "SERVICE_CONTRACT_CONTENT_UNDO_UPDATE_PLAN";
const SERVICE_CONTRACT_CONTENT_UNDO_BASIC_PLAN = "SERVICE_CONTRACT_CONTENT_UNDO_BASIC_PLAN";
const SERVICE_CONTRACT_MODIFY_NOTES = "SERVICE_CONTRACT_MODIFY_NOTES"
const SERVICE_CONTRACT_ACCEPT_TERMS_AND_CONDITIONS = "SERVICE_CONTRACT_ACCEPT_TERMS_AND_CONDITIONS"

const types = {
    SERVICE_CONTRACT_CONTENT,
    SERVICE_CONTRACT_CONTENT_LOADING,
    SERVICE_CONTRACT_CONTENT_SUCCESS,
    SERVICE_CONTRACT_CONTENT_FAILURE,

    SERVICE_CONTRACT_CONTENT_ALL_PLAN,
    SERVICE_CONTRACT_CONTENT_ALL_PLAN_LOADING,
    SERVICE_CONTRACT_CONTENT_ALL_PLAN_SUCCESS,
    SERVICE_CONTRACT_CONTENT_ALL_PLAN_FAILURE,

    SERVICE_CONTRACT_CONTENT_UPDATE_TO_CONFIRM,
    SERVICE_CONTRACT_CONTENT_BACK_TO_UPDATE,
    SERVICE_CONTRACT_CONTENT_RESET_UPDATE_FORM,

    SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED,
    SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_LOADING,
    SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_SUCCESS,
    SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_FAILURE,

    SERVICE_CONTRACT_CONTENT_ADD_PLAN,
    SERVICE_CONTRACT_CONTENT_REMOVE_PLAN,
    SERVICE_CONTRACT_CONTENT_UPDATE_ITEM,
    SERVICE_CONTRACT_CONTENT_UPDATE_PLAN,
    SERVICE_CONTRACT_CONTENT_UNDO_UPDATE_PLAN,
    SERVICE_CONTRACT_CONTENT_UNDO_BASIC_PLAN,
    SERVICE_CONTRACT_MODIFY_NOTES,

    SERVICE_CONTRACT_ACCEPT_TERMS_AND_CONDITIONS
}
export default types;
