import types from "./types";
import {empty} from "../../../../utils/Common";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているservice_contractsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractContentState = {
    serviceContractContentLoading: false,
    serviceContractContentErrors: {},
    serviceContractContentData: {},

    serviceContractContentAllPlanLoading: false,
    serviceContractContentAllPlanError: {},
    serviceContractContentAllPlanData: {},
    note: "",
    terms_agreed: false,

    serviceContractContentAlterPlan: [],
    serviceContractContentAlterBasicPlan: {},
    savedTierItem: [],
    updatePlanStep: 0,

    confirmChangePlanLoading: false,
    confirmChangePlanError: {}
}

export default function serviceContractContentReducer(state = serviceContractContentState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // 契約内容照会取得
        // -----------------------------------------------
        case(types.SERVICE_CONTRACT_CONTENT_LOADING):
            return {
                ...state,
                serviceContractContentLoading: state.serviceContractContentLoading = true,
                serviceContractContentErrors: state.serviceContractContentErrors = {},
            }
        case(types.SERVICE_CONTRACT_CONTENT_SUCCESS):
            return {
                ...state,
                serviceContractContentLoading: state.serviceContractContentLoading = false,
                serviceContractContentData: state.serviceContractContentData = action.payload.service_contract_content
            }
        case(types.SERVICE_CONTRACT_CONTENT_FAILURE):
            return {
                ...state,
                serviceContractContentData: state.serviceContractContentData = {},
                serviceContractContentErrors: state.serviceContractContentErrors = action.payload.error,
                serviceContractContentLoading: state.serviceContractContentLoading = false
            }
        /**************
         * すべてのプランの取得
         *************/
        case(types.SERVICE_CONTRACT_CONTENT_ALL_PLAN_LOADING):
            return {
                ...state,
                serviceContractContentAllPlanLoading: state.serviceContractContentAllPlanLoading = true,
                serviceContractContentAllPlanError: state.serviceContractContentAllPlanError = {},
                updatePlanStep: state.updatePlanStep = 0
            }
        case(types.SERVICE_CONTRACT_CONTENT_ALL_PLAN_SUCCESS):
            return {
                ...state,
                serviceContractContentAllPlanLoading: state.serviceContractContentAllPlanLoading = false,
                serviceContractContentAllPlanData: state.serviceContractContentAllPlanData = action.payload.all_plans
            }
        case(types.SERVICE_CONTRACT_CONTENT_ALL_PLAN_FAILURE):
            return {
                ...state,
                serviceContractContentAllPlanLoading: state.serviceContractContentAllPlanLoading = false,
                serviceContractContentAllPlanData: state.serviceContractContentAllPlanData = {},
                serviceContractContentAllPlanError: state.serviceContractContentAllPlanError = action.payload.error
            }
        /**************
         * ナビアクション
         *************/
        case(types.SERVICE_CONTRACT_CONTENT_UPDATE_TO_CONFIRM):
            return {
                ...state,
                updatePlanStep: state.updatePlanStep = 1,
                terms_agreed: state.terms_agreed = false
            }
        case(types.SERVICE_CONTRACT_CONTENT_BACK_TO_UPDATE):
            return {
                ...state,
                updatePlanStep: state.updatePlanStep = 0,
                terms_agreed: state.terms_agreed = false
            }
        case(types.SERVICE_CONTRACT_CONTENT_RESET_UPDATE_FORM):
            return {
                ...state,
                note: state.note = "",
                terms_agreed: state.terms_agreed = false,
                updatePlanStep: state.updatePlanStep = 0,
                serviceContractContentAlterPlan: state.serviceContractContentAlterPlan = [],
                serviceContractContentAlterBasicPlan: state.serviceContractContentAlterBasicPlan = {}
            }
        /**************
         * ご契約内容変更のリクエスト
         *************/
        case(types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_LOADING):
            return {
                ...state,
                confirmChangePlanLoading: state.confirmChangePlanLoading = true,
                confirmChangePlanError: state.confirmChangePlanError = {}
            }

        case(types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_SUCCESS):
            return {
                ...state,
                confirmChangePlanLoading: state.confirmChangePlanLoading = false,
                updatePlanStep: state.updatePlanStep = 2
            }
        case(types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_FAILURE):
            return {
                ...state,
                confirmChangePlanLoading: state.confirmChangePlanLoading = false,
                confirmChangePlanError: state.confirmChangePlanError = action.payload.error
            }
        /**************
         * 契約中プランの変更アクション
         *************/
        case(types.SERVICE_CONTRACT_CONTENT_UPDATE_PLAN):
            return {
                ...state,
                serviceContractContentAlterBasicPlan: state.serviceContractContentAlterBasicPlan = action.payload.ProductObject
            }
        case(types.SERVICE_CONTRACT_CONTENT_ADD_PLAN):
        case(types.SERVICE_CONTRACT_CONTENT_UPDATE_ITEM):
        case(types.SERVICE_CONTRACT_CONTENT_REMOVE_PLAN):
            if(!empty(action.payload.ProductObject.ItemData)) {
                return {
                ...state,
                    serviceContractContentAlterPlan: [...state.serviceContractContentAlterPlan, action.payload.ProductObject],
                    savedTierItem: [...state.savedTierItem.filter((item, index) => { return item.product_rate_plan_id !== action.payload.ProductObject.PlanData.product_rate_plan_id} ),
                        {...action.payload.ProductObject.ItemData[0], "product_rate_plan_id": action.payload.ProductObject.PlanData.product_rate_plan_id}
                    ]
                }
            } else {
                return {
                    ...state,
                    serviceContractContentAlterPlan: [...state.serviceContractContentAlterPlan, action.payload.ProductObject],
                }
            }
        case(types.SERVICE_CONTRACT_MODIFY_NOTES):
            return {
                ...state,
                note: state.note = action.payload.notes
            }
        case(types.SERVICE_CONTRACT_ACCEPT_TERMS_AND_CONDITIONS):
            return {
                ...state,
                terms_agreed: state.terms_agreed = action.payload.terms_agreed
            }
        case(types.SERVICE_CONTRACT_CONTENT_UNDO_UPDATE_PLAN):
            return {
                ...state,
                serviceContractContentAlterPlan: state.serviceContractContentAlterPlan.filter((item, index) => item?.PlanData?.product_rate_plan_id !== action.payload.PlanId)
            }
        case(types.SERVICE_CONTRACT_CONTENT_UNDO_BASIC_PLAN):
            return {
                ...state,
                serviceContractContentAlterBasicPlan: state.serviceContractContentAlterBasicPlan = {}
            }
        default:
            return state
    }
}
