import {default as types} from "./types";
/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているrequestsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const contactState = {
    contactEditFormMessage: ""
};

/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function contactReducer(state = contactState, action) {
    switch (action.type) {
        case(types.RECEIVE_CONTACT_FORM_MESSAGE):
            return {
                ...state,
                contactEditFormMessage: state.contactEditFormMessage = action.payload.message
            }
        case(types.SEND_CONTACT_FORM_SUCCESS):
            return {
                ...state,
                contactEditFormMessage: state.contactEditFormMessage = ""
            }
        default:
            return state;
    }
}
