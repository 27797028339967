/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

/**
 * 通知一覧取得
 * @type {string}
 */
export const MANAGER_REQUEST_NOTICES = 'MANAGER_REQUEST_NOTICES';
export const MANAGER_REQUEST_NOTICES_LOADING = 'MANAGER_REQUEST_NOTICES_LOADING';
export const MANAGER_REQUEST_NOTICES_SUCCESS = 'MANAGER_REQUEST_NOTICES_SUCCESS';
export const MANAGER_REQUEST_NOTICES_FAILURE = 'MANAGER_REQUEST_NOTICES_FAILURE';

export const MANAGER_TOGGLE_REGISTER_NOTICE = 'MANAGER_TOGGLE_REGISTER_NOTICE';
export const MANAGER_TOGGLE_DELETE_NOTICE = "MANAGER_TOGGLE_DELETE_NOTICE";

export const MANAGER_NOTIFICATION_CHOOSE_SEARCH_ACCOUNT = "MANAGER_NOTIFICATION_CHOOSE_SEARCH_ACCOUNT"

export const MANAGER_INITIALIZE_ACCOUNT_CONTENTS = "MANAGER_INITIALIZE_ACCOUNT_CONTENTS"


export const MANAGER_SEARCH_ACCOUNT_BY_KEYWORD = "MANAGER_SEARCH_ACCOUNT_BY_KEYWORD";
export const MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_LOADING = "MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_LOADING"
export const MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_SUCCESS = "MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_SUCCESS"
export const MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_FAILURE = "MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_FAILURE"

export const MANAGER_ADD_NOTICE = "MANAGER_ADD_NOTICE";
export const MANAGER_ADD_UPDATE_NOTICE_LOADING = "MANAGER_ADD_UPDATE_NOTICE_LOADING";
export const MANAGER_ADD_NOTICE_SUCCESS = "MANAGER_ADD_NOTICE_SUCCESS";
export const MANAGER_ADD_NOTICE_FAILURE = "MANAGER_ADD_NOTICE_FAILURE";

export const MANAGER_UPDATE_NOTICE = "MANAGER_UPDATE_NOTICE";

// 削除
export const MANAGER_DELETE_NOTICE_REQUEST = 'MANAGER_DELETE_NOTICE_REQUEST';
export const MANAGER_DELETE_NOTICE_LOADING = "MANAGER_DELETE_NOTICE_LOADING";
export const MANAGER_DELETE_NOTICE_SUCCESS = "MANAGER_DELETE_NOTICE_SUCCESS";
export const MANAGER_DELETE_NOTICE_FAILURE = "MANAGER_DELETE_NOTICE_FAILURE";



const types = {
    MANAGER_REQUEST_NOTICES,
    MANAGER_REQUEST_NOTICES_LOADING,
    MANAGER_REQUEST_NOTICES_SUCCESS,
    MANAGER_REQUEST_NOTICES_FAILURE,

    MANAGER_TOGGLE_REGISTER_NOTICE,
    MANAGER_TOGGLE_DELETE_NOTICE,

    MANAGER_NOTIFICATION_CHOOSE_SEARCH_ACCOUNT,

    MANAGER_SEARCH_ACCOUNT_BY_KEYWORD,
    MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_LOADING,
    MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_SUCCESS,
    MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_FAILURE,

    MANAGER_ADD_NOTICE,
    MANAGER_ADD_UPDATE_NOTICE_LOADING,
    MANAGER_ADD_NOTICE_SUCCESS,
    MANAGER_ADD_NOTICE_FAILURE,

    MANAGER_UPDATE_NOTICE,

    MANAGER_DELETE_NOTICE_REQUEST,
    MANAGER_DELETE_NOTICE_LOADING,
    MANAGER_DELETE_NOTICE_SUCCESS,
    MANAGER_DELETE_NOTICE_FAILURE
};

export default types;
