import React from 'react';
import {Box, Button, Card, Container, FormLabel, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import {empty} from "../../../../state/utils/Common";

/**
 * /manager/users コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    mainBox: {
        width: 400,
        height: 500,
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7)
    },
    errorBox: {
        width: 400,
        height: 75
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 *
 * @param loading
 * @param error
 * @param data
 * @param transferApproveSendError
 * @param transferApproveSendLoading
 * @param requestId
 * @param approveTransferRequestSend
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const TransferConfirmPage = (
    {
        loading,
        error,
        data,

        transferApproveSendError,
        transferApproveSendLoading,
        requestId,
        approveTransferRequestSend,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('transfer_approval.title')}
        >
            <Container maxWidth={false}>
                <Grid
                    style={{paddingTop: "43px"}}
                    container
                    spacing={0}
                    align="center"
                    justify="center"
                    direction="column"
                >
                    {loading === false && !empty(data) &&
                        <Grid item>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <Card className={classes.mainBox}>
                                    <Typography variant={"h2"} style={{fontSize: 18}}>
                                        {t('transfer_approval.title')}
                                    </Typography>
                                    <Grid container spacing={1} style={{paddingTop: 20}}>
                                        <Grid item xs={12}>
                                            <FormLabel style={{textAlign: "left", float: "left"}}>
                                                {t('transfer_approval.service_name')}
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"} style={{float: "left"}}>
                                                {data.service_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{paddingTop: 20}} spacing={1}>
                                        <Grid item xs={12}>
                                            <FormLabel style={{textAlign: "left", float: "left"}}>
                                                {t('transfer_approval.associated_account')}
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"} style={{float: "left"}}>
                                                {data.ebis_log_id} {data.ebis_client_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{paddingTop: 20}} spacing={1}>
                                        <Grid item xs={12}>
                                            <FormLabel style={{textAlign: "left", float: "left"}}>
                                                {t('transfer_approval.transfer_expected_date')}
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"} style={{float: "left"}}>
                                                {data.transfer_at}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{paddingTop: 20}} spacing={1}>
                                        <Grid item xs={12}>
                                            <FormLabel style={{textAlign: "left", float: "left"}}>
                                                {t('transfer_approval.transfer_supplier_name')}
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"} style={{float: "left"}}>
                                                {data.transfer_from_account_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{paddingTop: 20}} spacing={1}>
                                        <Grid item xs={12}>
                                            <FormLabel style={{textAlign: "left", float: "left"}}>
                                                {t('transfer_approval.transfer_manager')}
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"} style={{float: "left"}}>
                                                {data.transfer_from_user_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{paddingTop: 36}} spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                color={"primary"}
                                                variant={"contained"}
                                                style={{paddingLeft: 40, paddingRight: 40}}
                                                onClick={() => {
                                                    approveTransferRequestSend(requestId)
                                                }}
                                                disabled={transferApproveSendLoading}
                                            >{t('actions.approve')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Box>
                        </Grid>
                    }
                    {loading === false && empty(data) && !empty(error) &&
                        <Grid item alignContent={"center"} alignItems={"center"} justify={"center"}>
                            <Box display="flex" alignItems="center" alignContent={"center"} alignSelf={"center"}
                                 justifyItems={"center"} flexDirection="column">
                                <Card className={classes.errorBox}>
                                    <Grid container spacing={1} style={{paddingTop: 20}}>
                                        <Grid item xs={12}>
                                            {error?.message?.code === 'E400_REQUEST_ALREADY_ACCEPTED' ?
                                                <FormLabel>
                                                    既に受諾済みです。
                                                </FormLabel>
                                            : error?.message?.code === "E404_INVALID_REQUEST" &&
                                                <FormLabel>
                                                    このURLは無効です。
                                                </FormLabel>
                                            }
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Container>
        </Page>
    );
};

export default TransferConfirmPage;
