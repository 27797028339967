import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// ユーザー一覧
//-------------------------------------------------

/**
 * ユーザー一覧取得リクエスト
 * @param {string} access_token - auth0アクセストークン
 * @param {string} user_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, user_id}, type: string}}
 */
export function loadUserList(access_token, user_id) {
    return {
        type: types.REQUEST_USERS,
        payload: {
            access_token: access_token,
            user_id: user_id
        }
    };
}

/**
 * ユーザー一覧取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadingUserList() {
    return {
        type: types.REQUEST_USERS_LOADING
    }
}

/***
 * ユーザー一覧取得リクエスト（成功）
 * @param {Object} users 取得したユーザーデータ
 * @returns {{payload, type: string}}
 */
export function loadedUserList(users) {
    return {
        type: types.REQUEST_USERS_SUCCESS,
        payload: users
    }
}

/***
 * ユーザー一覧取得リクエスト（失敗)
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function failureUserList(error) {
    return {
        type: types.REQUEST_USERS_FAILURE,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// ユーザー一覧のページネーション
//-------------------------------------------------

/***
 *  ユーザー一覧のページ変更
 * @param {number} PageNo
 * @returns {{payload: {page_no}, type: string}}
 */
export function userChangePage(PageNo) {
    return {
        type: types.USER_CHANGE_PAGE,
        payload: {
            page_no: PageNo
        }
    }
}

//-------------------------------------------------
// ユーザー検索
//-------------------------------------------------

/***
 * ユーザー検索リクエスト
 * @param {string} searchName - 検索キーワード
 * @param {string} access_token - auth0アクセストークン
 * @param {number} searchMin - 文字の最低制限
 * @returns {{payload: {access_token, searchUsers}, type: string}}
 */
export function requestSearchUsers(searchName, access_token, searchMin = 0) {
    return {
        type: types.SEARCH_USER_REQUEST,
        payload: {
            searchUsers: searchName,
            access_token: access_token
        }
    };
}

/***
 * ユーザー検索リクエスト(成功）
 * @returns {{type: string}}
 */
export function loadingSearchUsers() {
    return {
        type: types.SEARCH_USER_LOADING,
    }
}

/***
 * ユーザー検索リクエスト(失敗)
 * @param {Object} users - 検索結果
 * @returns {{payload: {userResult}, type: string}}
 */
export function successSearchUsers(users) {
    return {
        type: types.SEARCH_USER_SUCCESS,
        payload: {
            userResult: users
        }
    }
}

/***
 * ユーザー検索リクエスト(失敗）
 * @param {Object} exception - エラー内容
 * @returns {{payload: {error_code: number, error}, type: string}}
 */
export function failureSearchUsers(exception) {
    return {
        type: types.SEARCH_USER_FAILURE,
        payload: {
            error_code: 401,
            error: exception.message
        }
    }
}


//-------------------------------------------------
// ユーザー登録
//-------------------------------------------------

/***
 * 新規ユーザー登録モーダル表示の開閉
 * @param {Object} activeUserid - （編集モードのみ）選択したユーザー
 * @returns {{payload: {user_id: {}}, type: string}}
 */
export function viewRegisterToggle(activeUserid = {}) {
    return {
        type: types.TOGGLE_REGISTER,
        payload: {
            user_id: activeUserid
        }
    }
}

/***
 * 新規ユーザー追加リクエスト
 * @param {Object} user - 新規ユーザーデータ
 * @param {string} access_token - auth0アクセストークン
 * @param {string} currentUserUUID - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, user, uuid}, type: string}}
 */
export function registerNewUser(user, access_token, currentUserUUID) {
    return {
        type: types.ADD_USER,
        payload: {
            user: user,
            access_token: access_token,
            uuid: currentUserUUID
        }
    }
}

//-------------------------------------------------
// ユーザー更新
//-------------------------------------------------

/***
 * ユーザー情報の更新リクエスト
 * @param {Object} user - （編集モードのみ）変更ユーザー
 * @param {Object} new_user_info - 変更・新規ユーザーデータ
 * @param {string} access_token - auth0 アクセストークン
 * @param {string} current_user_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, user_id: *, current_user_id, user}, type: string}}
 */
export function updateUser(user, new_user_info, access_token, current_user_id) {
    return {
        type: types.UPDATE_USER,
        payload: {
            user: new_user_info,
            user_id: user?.uuid,
            access_token: access_token,
            current_user_id: current_user_id
        }
    }
}

//****---------------------------------------------
// ユーザー更新はここまで
//****---------------------------------------------

/***
 * 新規ユーザー・ユーザー更新リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function userRegistrationLoad() {
    return {
        type: types.ADD_USER_LOADING
    }
}

/***
 * 新規ユーザー・ユーザー更新リクエスト（成功）
 * @returns {{type: string}}
 */
export function registerNewUserSuccess() {
    return {
        type: types.ADD_USER_SUCCESS
    }
}

/***
 * 新規ユーザー・ユーザー更新リクエスト（失敗）
 * @param {Object} e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function registerNewUserFailure(e) {
    return {
        type: types.ADD_USER_FAILURE,
        payload: {error: e}
    }
}

//-------------------------------------------------
// ユーザー削除
//-------------------------------------------------

/***
 * ユーザー削除モーダル開閉
 * @param {string} accountId - ログインしたユーザーのアカウントID
 * @returns {{payload: {accountId}, type: string}}
 */
export function viewUserDeleteToggle(accountId) {
    return {
        type: types.DELETE_USER_TOGGLE,
        payload: {
            accountId: accountId
        }
    }
}

/***
 * ユーザー削除リクエスト
 * @param user - 削除ユーザーID
 * @param access_token - auth0 アクセストークン
 * @param account_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, account_id, user}, type: string}}
 */
export function deleteUsers(user, access_token, account_id) {
    return {
        type: types.DELETE_USER,
        payload: {
            user: user,
            access_token: access_token,
            account_id: account_id
        }
    }
}

/***
 * ユーザー削除リクエスト(ロード中）
 * @returns {{type: string}}
 */
export function deleteLoading() {
    return {
        type: types.DELETE_USER_LOADING
    }
}

/***
 * ユーザー削除リクエスト(成功）
 * @returns {{type: string}}
 */
export function deleteUserSuccess() {
    return {
        type: types.DELETE_USER_SUCCESS
    }
}

/***
 * ユーザー削除リクエスト(失敗）
 * @param {object} e - エラー内容
 * @returns {{payload, type: string}}
 */
export function deleteUserFailure(e) {
    return {
        type: types.DELETE_USER_FAILURE,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// ブロック解除
//-------------------------------------------------

/**
 * ブロックされたAuth0ユーザーの解除処理。
 * @param accessToken
 * @param userId
 * @param accountId
 * @returns {{payload: {accountId, accessToken, userId}, type: string}}
 */
export function unlockAuth0User(accessToken, userId, accountId) {
    return {
        type: types.AUTH0_UNLOCK_USER,
        payload: {
            accessToken: accessToken,
            userId: userId,
            accountId: accountId
        }
    }
}

/**
 * 解除中
 * @returns {{type: string}}
 */
export function unlockAuth0UserLoading() {
    return {
        type: types.AUTH0_UNLOCK_USER_LOADING
    }
}

/**
 * 解除成功
 * @returns {{type: string}}
 */
export function unlockAuth0UserSuccess() {
    return {
        type: types.AUTH0_UNLOCK_USER_SUCCESS
    }
}

/**
 * 解除失敗
 * @param e
 * @returns {{type: string}}
 */
export function unlockAuth0UserFailed(e) {
    return {
        type: types.AUTH0_UNLOCK_USER_FAILED,
        payload: {
            error: e
        }
    }
}

/**
 * モーダルの非表示エベント
 * @returns {{type: string}}
 */
export function closeUnlockAuth0UserModal() {
    return {
        type: types.AUTH0_UNLOCK_USER_CLOSE_MODAL
    }
}

//-------------------------------------------------
// 認証メール
//-------------------------------------------------

/**
 * 認証メールの再送信エベント
 * @param accessToken
 * @param userId
 * @param accountId
 * @returns {{payload: {accountId, accessToken, userId}, type: string}}
 */
export function resendAuth0VerifyMail(accessToken, userId, accountId) {
    return {
        type: types.AUTH0_RESEND_VERIFY_EMAIL,
        payload: {
            accessToken: accessToken,
            userId: userId,
            accountId: accountId
        }
    }
}

export function resendAuth0VerifyMailLoading() {
    return {
        type: types.AUTH0_RESEND_VERIFY_EMAIL_LOADING
    }
}

export function resendAuth0VerifyMailSuccess() {
    return {
        type: types.AUTH0_RESEND_VERIFY_EMAIL_SUCCESS
    }
}

export function resendAuth0VerifyMailFailure(e) {
    return {
        type: types.AUTH0_RESEND_VERIFY_EMAIL_FAILED,
        payload: {
            error: e
        }
    }
}

export function resendAuth0VerifyMailCloseNotification() {
    return {
        type: types.AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL
    }
}

const actions = {
    loadUserList,
    loadingUserList,
    loadedUserList,
    failureUserList,
    userChangePage,

    viewRegisterToggle,

    registerNewUser,
    updateUser,
    userRegistrationLoad,
    registerNewUserSuccess,
    registerNewUserFailure,

    viewUserDeleteToggle,
    deleteUsers,
    deleteLoading,
    deleteUserSuccess,
    deleteUserFailure,

    requestSearchUsers,
    loadingSearchUsers,
    successSearchUsers,
    failureSearchUsers,

    unlockAuth0User,
    unlockAuth0UserLoading,
    unlockAuth0UserSuccess,
    unlockAuth0UserFailed,
    closeUnlockAuth0UserModal,

    resendAuth0VerifyMail,
    resendAuth0VerifyMailLoading,
    resendAuth0VerifyMailSuccess,
    resendAuth0VerifyMailFailure,
    resendAuth0VerifyMailCloseNotification,
};
export default actions;
