/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（notifications）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const notificationWatcherSagas = Object.values(watchersSagas);
export {default as notificationListOperations} from "./operations";
export {default as notificationListTypes} from "./types";

export {notificationListState, default as notificationReducer} from './reducers';
