import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Divider,
    FormLabel,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Link} from "react-router-dom";
import Page from "../Page";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";
import urlLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @param receiveContactFormMessage
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmPage = (
    {
        t,
        message,
        confirmContactRequest,
        SendSuccessCallBackAction,
        managerProfile,
    }) => {
    const classes = useStyles();
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Page
            className={classes.root}
            title={t('requests.title_user')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('contact.title')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.contact.index.href}>
                                <Typography color="textPrimary">{t('contact.title')}</Typography>
                            </Link></Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography variant={"h4"}>{t('contact.contact_form')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Typography style={{marginBottom: 20}}
                                        variant={"body1"}>{t('contact.confirm_content_description')}</Typography>
                            <FormLabel>{t('label_name.message')}</FormLabel>
                            <Typography variant={"body1"} style={{whiteSpace: "pre-wrap"}}>{message}</Typography>
                        </Box>
                        <Box paddingBottom={5}>
                            <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}>
                                <Grid item xs={12} style={{textAlign: "center"}}>
                                    <Link to={"/contact"}>
                                        <Button variant={"contained"} style={{marginRight: 15}} color={"secondary"}>
                                            {t('contact.confirm.back_to_input')}
                                        </Button>
                                    </Link>
                                    <Button onClick={() => (async () => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        confirmContactRequest(access_token, managerProfile?.account?.uuid, message, SendSuccessCallBackAction)
                                    })()
                                    } variant={"contained"}
                                            color={"primary"}>
                                        {t('actions.send')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default ConfirmPage;
