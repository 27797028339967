import types from "./types";
/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// ユーザー一覧イベント
//-------------------------------------------------
/***
 * 管理者側のユーザー一覧のデータリクエストイベント
 *
 * @param {string} access_token - アクセストークン
 * @returns {{payload: {access_token}, type: string}}
 */
export function managerLoadUserList(access_token) {
    return {
        type: types.MANAGER_REQUEST_USERS,
        payload: {
            access_token: access_token
        }
    };
}

/**
 * 管理者側のユーザー一覧取得リクエスト（ロード中）
 *
 * @returns {{type: string}}
 */
export function managerLoadingUserList() {
    return {
        type: types.MANAGER_REQUEST_USERS_LOADING
    }
}

/**
 * 管理者側のユーザー一覧の取得リクエスト (成功)
 *
 * @param users - 取得したユーザー一覧
 * @returns {{payload, type: string}}
 */
export function managerLoadedUserList(users) {
    return {
        type: types.MANAGER_REQUEST_USERS_SUCCESS,
        payload: users
    }
}

/**
 *管理者側のユーザー一覧の取得リクエスト (失敗)
 *
 * @param error - エラーオブジェクト
 * @returns {{payload: {managerError, managerError_code}, type: string}}
 */
export function managerFailureUserList(error) {
    return {
        type: types.MANAGER_REQUEST_USERS_FAILURE,
        payload: {
            managerError: error
        }
    }
}

//-------------------------------------------------
// ユーザー一覧のペジーネーション
//-------------------------------------------------

/**
 * パジーネーションイベント
 *
 * @param PageNo - ページ番号
 * @returns {{payload: {page_no}, type: string}}
 */
export function managerUserChangePage(PageNo) {
    return {
        type: types.MANAGER_CHANGE_PAGE,
        payload: {
            page_no: PageNo
        }
    }
}

//-------------------------------------------------
// ユーザー検索
//-------------------------------------------------

/**
 * ユーザー検索APIリクエスト
 *
 * @param searchName - 検索名
 * @param access_token - auth0アクセストークン
 * @returns {{payload: {access_token, searchUsers}, type: string}}
 */
export function managerRequestSearchUsers(searchName, access_token) {
    return {
        type: types.MANAGER_SEARCH_USER_REQUEST,
        payload: {
            searchUsers: searchName,
            access_token: access_token
        }
    };
}

/**
 * ユーザー検索リクエスト(ロード中)
 *
 * @returns {{type: string}}
 */
export function managerLoadingSearchUsers() {
    return {
        type: types.MANAGER_SEARCH_USER_LOADING,
    }
}

/**
 * ユーザー検索結果リクエスト(成功）
 *
 * @param users
 * @returns {{payload: {userResult}, type: string}}
 */
export function managerSuccessSearchUsers(users) {
    return {
        type: types.MANAGER_SEARCH_USER_SUCCESS,
        payload: {
            userResult: users
        }
    }
}

/**
 * ユーザー検索結果リクエスト(失敗）
 *
 * @param exception - HTTP エラー
 * @returns {{payload: {managerError, managerError_code: number}, type: string}}
 */
export function managerFailureSearchUsers(exception) {
    return {
        type: types.MANAGER_SEARCH_USER_FAILURE,
        payload: {
            managerError_code: 401,
            managerError: exception.message
        }
    }
}


//-------------------------------------------------
// ユーザー登録イベント
// ※ ユーザー更新イベントもここにふくめています。
//-------------------------------------------------

/**
 * ユーザーモダール表示・非表示スイッチ
 *
 * @param user - 選択したユーザー、(新規ユーザー＝null)
 * @returns {{payload: {user: null}, type: string}}
 */
export function managerViewRegisterToggle(user = null) {
    return {
        type: types.MANAGER_TOGGLE_REGISTER,
        payload: {
            user: user
        }
    }
}

/**
 *  ユーザー登録(ロード中)
 *
 * @returns {{type: string}}
 */
export function managerUserRegistrationLoad() {
    return {
        type: types.MANAGER_REGISTER_LOADING
    }
}

/**
 * ユーザー登録リクエスト
 *
 * @param user
 * @param access_token
 * @returns {{payload: {access_token, user: {user}}, type: string}}
 */
export function managerRegisterNewUser(user, access_token) {
    return {
        type: types.MANAGER_ADD_USER,
        payload: {
            user: user,
            access_token: access_token
        }
    }
}

//-------------------------------------------------
// ユーザー情報更新
//-------------------------------------------------

/**
 * ユーザーデータの更新リクエスト

 * @param user - 選択したユーザーID
 * @param new_user_info - 更新したユーザーデータ
 * @param access_token - auth0アクセストークン
 * @returns {{payload: {access_token, user_id, user: {user}}, type: string}}
 */
export function managerUpdateUser(user, new_user_info, access_token) {
    return {
        type: types.MANAGER_UPDATE_USER_REQUEST,
        payload: {
            user: new_user_info,
            user_id: user,
            access_token: access_token
        }
    }
}

//****---------------------------------------------
// ^^^ユーザー情報更新^^^^
//****---------------------------------------------


/**
 * ユーザー登録・更新リクエスト（成功）
 *
 * @returns {{type: string}}
 */
export function managerRegisterNewUserSuccess() {
    return {
        type: types.MANAGER_ADD_USER_SUCCESS
    }
}

/**
 * ユーザー登録・更新リクエスト（失敗)
 *
 * @param e - エラー内容
 * @returns {{payload: {managerError}, type: string}}
 */
export function managerRegisterNewUserFailure(e) {
    return {
        type: types.MANAGER_ADD_USER_FAILURE,
        payload: {managerError: e}
    }
}

//-------------------------------------------------
// ユーザー削除イベント
//-------------------------------------------------

/**
 * ユーザー削除モーダルの表示を無効・有効にするイベント
 *
 * @param accountId - ユーザーID
 * @returns {{payload: {accountId}, type: string}}
 */
export function managerViewUserDeleteToggle(accountId) {
    return {
        type: types.MANAGER_TOGGLE_USER_DELETE,
        payload: {
            accountId: accountId
        }
    }
}

/**
 *
 * APIにユーザー削除を送信するイベント
 *
 * @param user - ユーザーID
 * @param access_token - アクセストークン
 * @returns {{payload: {access_token, user}, type: string}}
 */
export function managerDeleteUsers(user, access_token) {
    return {
        type: types.MANAGER_DELETE_USER_REQUEST,
        payload: {
            user: user,
            access_token: access_token
        }
    }
}

/**
 * 削除の実行中
 * @returns {{type: string}}
 */
export function managerDeleteUserLoading() {
    return {
        type: types.MANAGER_DELETE_USER_LOADING
    }
}

/**
 * ユーザー削除リクエスト（成功）
 *
 * @returns {{type: string}}
 */
export function managerDeleteUserSuccess() {
    return {
        type: types.MANAGER_DELETE_USER_SUCCESS
    }
}

/**
 * ユーザー削除作成リクエスト（失敗）
 *
 * @param e - エラーメッセージ
 * @returns {{payload, type: string}}
 */
export function managerDeleteUserFailure(e) {
    return {
        type: types.MANAGER_DELETE_USER_FAILURE,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// ブロック解除
//-------------------------------------------------

/**
 * ブロックされたAuth0ユーザーの解除処理。
 * @param accessToken
 * @param userId
 * @param accountId
 * @returns {{payload: {accountId, accessToken, userId}, type: string}}
 */
export function managerUnlockAuth0User(accessToken, userId, accountId) {
    return {
        type: types.MANAGER_AUTH0_UNLOCK_USER,
        payload: {
            accessToken: accessToken,
            userId: userId,
            accountId: accountId
        }
    }
}

/**
 * 解除中
 * @returns {{type: string}}
 */
export function managerUnlockAuth0UserLoading() {
    return {
        type: types.MANAGER_AUTH0_UNLOCK_USER_LOADING
    }
}

/**
 * 解除成功
 * @returns {{type: string}}
 */
export function managerUnlockAuth0UserSuccess() {
    return {
        type: types.MANAGER_AUTH0_UNLOCK_USER_SUCCESS
    }
}

/**
 * 解除失敗
 * @param e
 * @returns {{type: string}}
 */
export function managerUnlockAuth0UserFailed(e) {
    return {
        type: types.MANAGER_AUTH0_UNLOCK_USER_FAILED,
        payload: {
            error: e
        }
    }
}

/**
 * モーダルの非表示エベント
 * @returns {{type: string}}
 */
export function managerCloseUnlockAuth0UserModal() {
    return {
        type: types.MANAGER_AUTH0_UNLOCK_USER_CLOSE_MODAL
    }
}

//-------------------------------------------------
// 認証メール
//-------------------------------------------------

/**
 * 認証メールの再送信エベント
 * @param accessToken
 * @param userId
 * @param accountId
 * @returns {{payload: {accountId, accessToken, userId}, type: string}}
 */
export function managerResendAuth0VerifyMail(accessToken, userId, accountId) {
    return {
        type: types.MANAGER_AUTH0_RESEND_VERIFY_EMAIL,
        payload: {
            accessToken: accessToken,
            userId: userId,
            accountId: accountId
        }
    }
}

export function managerResendAuth0VerifyMailLoading() {
    return {
        type: types.MANAGER_AUTH0_RESEND_VERIFY_EMAIL_LOADING
    }
}

export function managerResendAuth0VerifyMailSuccess() {
    return {
        type: types.MANAGER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS
    }
}

export function managerResendAuth0VerifyMailFailure(e) {
    return {
        type: types.MANAGER_AUTH0_RESEND_VERIFY_EMAIL_FAILED,
        payload: {
            error: e
        }
    }
}

export function managerResendAuth0VerifyMailCloseNotification() {
    return {
        type: types.MANAGER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL
    }
}

const actions = {
    managerLoadUserList,
    managerLoadingUserList,
    managerLoadedUserList,
    managerFailureUserList,
    managerUserChangePage,

    managerViewRegisterToggle,
    managerUserRegistrationLoad,
    managerRegisterNewUser,
    managerUpdateUser,
    managerRegisterNewUserSuccess,
    managerRegisterNewUserFailure,

    managerRequestSearchUsers,
    managerLoadingSearchUsers,
    managerSuccessSearchUsers,
    managerFailureSearchUsers,

    managerViewUserDeleteToggle,
    managerDeleteUsers,
    managerDeleteUserLoading,
    managerDeleteUserSuccess,
    managerDeleteUserFailure,

    managerUnlockAuth0User,
    managerUnlockAuth0UserLoading,
    managerUnlockAuth0UserSuccess,
    managerUnlockAuth0UserFailed,
    managerCloseUnlockAuth0UserModal,

    managerResendAuth0VerifyMail,
    managerResendAuth0VerifyMailLoading,
    managerResendAuth0VerifyMailSuccess,
    managerResendAuth0VerifyMailFailure,
    managerResendAuth0VerifyMailCloseNotification,
};
export default actions
