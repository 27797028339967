import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../../state/ducks/root";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {notificationListOperations} from "../../../../../state/ducks/user/notifications";
import AllNotificationView from "../../../../components/user/notifications/all/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        allNotificationsLoading: state.notification.allNotificationsLoading,
        allNotifications: state.notification.allNotifications
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    loadNotificationAllList: notificationListOperations.loadNotificationAllList,

}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _allNotificationsContainer = (
    {
        managerProfile,
        loadSidebarItems,
        loadNotificationAllList,

        allNotificationsLoading,
        allNotifications,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadNotificationAllList(access_token, managerProfile?.account?.uuid)
            })()
        }
    }, [getAccessTokenSilently, loadNotificationAllList, managerProfile])

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.notifications)
    }, [loadSidebarItems])

    return (
        <AllNotificationView
            loading={allNotificationsLoading}
            profile={managerProfile}
            t={t}
            notifications={allNotifications}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const NotificationsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_allNotificationsContainer)

export default NotificationsContainer;

