import ApiUtils from "../../../utils/ApiUtils";
import {POST_CONTACT_FORM_API_PATH} from "./constants";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /requestsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */
/**
 * 問い合わせフォームの送信
 * @param accessToken
 * @param accountId
 * @param message
 * @returns {AxiosPromise}
 */
export function postContactFormAPI(accessToken, accountId, message) {
    return new ApiUtils(accessToken).post(
        POST_CONTACT_FORM_API_PATH.replace('{UID}', accountId),
        {'body': message},
        null
    )
}

