import {default as types} from "./types";
/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
import {takeLeading} from "redux-saga/effects";
import {getCancelReasons, sendCancelRequest} from "./sagas";

export function* fetchServiceContractTerminate() {
    yield takeLeading(types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST, sendCancelRequest);
    yield takeLeading(types.GET_SERVICE_CONTRACT_CANCEL_REASONS, getCancelReasons);
}
