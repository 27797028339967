import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import LoginAsUtils from "../../../state/utils/LoginAsUtils";

/**
 * サインインコンテイナです。
 * @returns {JSX.Element}
 * @constructor
 */
const UserLoginContainer = () => {
    const {
        loginWithRedirect,
    } = useAuth0();

    LoginAsUtils.remove();

    useEffect(() => {
        loginWithRedirect({
            redirectUri: window.location.origin + '/manager/accounts'
        })
    }, [loginWithRedirect]);

    return (
        <div>
        </div>
    )
}
export default UserLoginContainer
