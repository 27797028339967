export default class LoginAsUtils {
    static localStorageKey = "loginAsUUID"
    static localAccountName = "localAccountName"

    static get() {
        return window.localStorage.getItem(this.localStorageKey)
    }

    static getAccountName() {
        return window.localStorage.getItem(this.localAccountName)
    }

    static set(uuid) {
        return window.localStorage.setItem(this.localStorageKey, uuid)
    }

    static setAccountName(accountName) {
        return window.localStorage.setItem(this.localAccountName, accountName)
    }

    static remove() {
        window.localStorage.removeItem(this.localAccountName)
        return window.localStorage.removeItem(this.localStorageKey)
    }

    static isUser(managerProfile) {
        return !((managerProfile.is_user === false) && (managerProfile?.is_pretend !== true));
    }
}
