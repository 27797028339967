import {Button, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RestrictSales from "../../../../atoms/RestrictSales";
import managerUrlLinks from "../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";

export const AccountsListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.accounts[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 0,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={1}>
                <Link
                    to={managerUrlLinks.navigation.accounts.index.sub_links.service_contracts.href.replace('{UID}', props.data.accounts[props.index].uuid)}>
                    <Typography className={commonTheme.link} variant="body1">
                        {props.data.accounts[props.index].id}
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={3} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.accounts[props.index].name}
                </Typography>
            </Grid>
            <Grid item xs={5} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.accounts[props.index].note && props.data.accounts[props.index].note.length > 30 ?
                        props.data.accounts[props.index].note.substring(0, 30) + '...' :
                        props.data.accounts[props.index].note
                    }
                </Typography>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <Button
                    variant={"text"}
                    size={"small"}
                    className={commonTheme.listButton}
                    onClick={() => props.data.managerLoginEvent(props.data.accounts[props.index].uuid,  props.data.accounts[props.index].name,
                        () => {
                            window.open('/', '_blank');
                        })}
                >
                    <ExitToAppIcon
                        style={{fontSize: 12, marginRight: 3, marginTop: "-3px"}}/>{props.data.t('actions.login')}
                </Button>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <RestrictSales>
                    <Button
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.handleEditClick(props.data.accounts[props.index])}
                    >
                        <CreateIcon
                            style={{fontSize: 12, marginRight: 3, marginTop: "-3px"}}/>{props.data.t('actions.edit')}
                    </Button>
                </RestrictSales>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <RestrictSales>
                    <Button
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.handleDeleteClick(props.data.accounts[props.index])}
                    >
                        <DeleteIcon
                            style={{fontSize: 12, marginRight: 3, marginTop: "-3px"}}/>{props.data.t('actions.delete')}
                    </Button>
                </RestrictSales>
            </Grid>
        </Grid>
    )
}
