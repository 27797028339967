/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const APPROVE_TRANSFER_REQUEST = "APPROVE_TRANSFER_REQUEST"
export const APPROVE_TRANSFER_REQUEST_LOADING = "APPROVE_TRANSFER_REQUEST_LOADING"
export const APPROVE_TRANSFER_REQUEST_SUCCESS = "APPROVE_TRANSFER_REQUEST_SUCCESS"
export const APPROVE_TRANSFER_REQUEST_FAILED = "APPROVE_TRANSFER_REQUEST_FAILED"

export const SEND_APPROVE_TRANSFER_REQUEST = "SEND_APPROVE_TRANSFER_REQUEST"
export const SEND_APPROVE_TRANSFER_REQUEST_LOADING = "SEND_APPROVE_TRANSFER_REQUEST_LOADING"
export const SEND_APPROVE_TRANSFER_REQUEST_SUCCESS = "SEND_APPROVE_TRANSFER_REQUEST_SUCCESS"
export const SEND_APPROVE_TRANSFER_REQUEST_FAILED =  "SEND_APPROVE_TRANSFER_REQUEST_FAILED"

const types = {
    APPROVE_TRANSFER_REQUEST,
    APPROVE_TRANSFER_REQUEST_LOADING,
    APPROVE_TRANSFER_REQUEST_SUCCESS,
    APPROVE_TRANSFER_REQUEST_FAILED,

    SEND_APPROVE_TRANSFER_REQUEST,
    SEND_APPROVE_TRANSFER_REQUEST_LOADING,
    SEND_APPROVE_TRANSFER_REQUEST_SUCCESS,
    SEND_APPROVE_TRANSFER_REQUEST_FAILED
};
export default types;
