/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

/**
 * お知らせ作成・更新フォーム項目
 * @type {{end_at: null, detail: null, title: null, start_at: null, account_id: null}}
 */
const createEditForm = {
    title: null,
    detail: null,
    start_at: null,
    end_at: null,
    account_id: null
}

const forms = {
    createEditForm
}

export default forms
