/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /manager/notices 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

/**
 * お知らせ更新・追加フォーム妥当性確認
 */
export const mangerNoticeStoreValidation = {
    title: {
        length: {maximum: 80},
        presence: true
    },
    detail: {
        presence: true
    },
    start_at: {
        presence: true,
        dateRangeValidation : {
            compareField: "end_at",
            operator: "<="
        },
        format: {
            pattern: /^[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}$/,
            message: "^validator.bad_date_format"
        }
    },
    end_at: {
        presence: false,
        dateRangeValidation: {
            compareField: "start_at",
            operator: ">="
        }
    },
    type: {
        presence: true,
    },
    account_id: {
        presenceIf: {
            field: "type",
            operator: "=",
            value: "2",
            errorKey: "notify_account",
        }
    }


}
