import {managerAccountListOperations} from "../../../../state/ducks/manager/managerAccounts";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ManagerAccountView from "../../../components/manager/accounts/Page";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {rootOperations} from "../../../../state/ducks/root";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        managerProfile: state.root.managerProfile,

        managerAccountVisibleKeyword: state.managerAccount.managerAccountVisibleKeyword,
        managerAccountSearchKeyword: state.managerAccount.managerAccountSearchKeyword,
        managerAccountCurrentPage: state.managerAccount.managerAccountCurrentPage,
        managerAccountMaximumPage: state.managerAccount.managerAccountMaximumPage,
        managerAccountTotalRows: state.managerAccount.managerAccountTotalRows,
        accounts: state.managerAccount.managerAccountList,

        managerAccountIsRegisterView: state.managerAccount.managerAccountIsRegisterView,
        managerAccountIsEditView: state.managerAccount.managerAccountIsEditView,
        managerAccountRegisterError: state.managerAccount.managerAccountRegisterError,
        managerAccountRegisterLoading: state.managerAccount.managerAccountRegisterLoading,

        managerLoading: state.managerAccount.managerLoading,
        managerSearchLoad: state.managerAccount.managerSearchLoad,
        searchAccountResult: state.managerAccount.managerSearchAccountResult,
        deleteView: state.managerAccount.managerDeleteView,
        managerDeleteLoading: state.managerAccount.managerDeleteLoading,
        activeId: state.managerAccount.managerActiveId,
        managerError: state.managerAccount.managerError,
        managerDeleteError: state.managerAccount.managerDeleteError,
        managerAccountLoginLoading: state.managerAccount.managerAccountLoginLoading,
        managerAccountLoginError: state.managerAccount.managerAccountLoginError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    toggleRegisterView: managerAccountListOperations.managerViewRegisterToggle,
    registerAccount: managerAccountListOperations.managerRegisterNewAccount,
    searchAccount: managerAccountListOperations.managerRequestSearchAccounts,
    managerRequestChangeKSearchKeyWord: managerAccountListOperations.managerRequestChangeKSearchKeyWord,
    toggleDelete: managerAccountListOperations.managerViewDeleteToggle,
    managerViewEditToggle: managerAccountListOperations.managerViewEditToggle,
    managerEditAccount: managerAccountListOperations.managerEditAccount,
    managerDeleteAccount: managerAccountListOperations.managerDeleteAccount,
    managerChangePageAccountList: managerAccountListOperations.managerChangePageAccountList,
    managerLoadAccountList: managerAccountListOperations.managerLoadAccountList,
    managerViewRegisterToggleInitSFAccountLoad: managerAccountListOperations.managerViewRegisterToggleInitSFAccountLoad,

    managerLoginEvent: managerAccountListOperations.managerLoginEvent
}

const _managerContainerView = (
    {
        reLoad,
        managerProfile,

        accounts,
        managerAccountIsEditView,
        managerEditAccount,
        managerAccountRegisterError,
        managerAccountRegisterLoading,
        managerLoading,
        deleteView,
        activeId,
        toggleRegisterView,
        managerViewEditToggle,
        managerDeleteAccount,
        searchAccount,
        managerRequestChangeKSearchKeyWord,
        managerAccountVisibleKeyword,
        toggleDelete,
        managerLoadAccountList,
        managerViewRegisterToggleInitSFAccountLoad,
        managerDeleteError,
        managerDeleteLoading,
        loadSidebarItems,

        managerChangePageAccountList,
        managerAccountSearchKeyword,
        managerAccountCurrentPage,
        managerAccountMaximumPage,
        managerAccountTotalRows,

        managerAccountLoginLoading,
        managerAccountLoginError,
        managerLoginEvent,

        t
    }) => {
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!empty(managerProfile) && reLoad) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                managerLoadAccountList(access_token, managerAccountSearchKeyword,  managerAccountCurrentPage);
            })();
        }
    }, [getAccessTokenSilently, reLoad, managerProfile, managerLoadAccountList, managerAccountCurrentPage, managerAccountSearchKeyword])

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    return (
        <ManagerAccountView
            reLoad={reLoad}
            managerProfile={managerProfile}
            accounts={accounts}
            managerAccountIsEditView={managerAccountIsEditView}
            managerEditAccount={managerEditAccount}
            managerAccountRegisterError={managerAccountRegisterError}
            managerAccountRegisterLoading={managerAccountRegisterLoading}
            managerLoading={managerLoading}
            deleteView={deleteView}
            activeId={activeId}
            getAccessTokenSilently={getAccessTokenSilently}
            toggleRegisterView={toggleRegisterView}
            managerViewEditToggle={managerViewEditToggle}
            managerDeleteAccount={managerDeleteAccount}
            managerAccountVisibleKeyword={managerAccountVisibleKeyword}
            managerRequestChangeKSearchKeyWord={managerRequestChangeKSearchKeyWord}
            searchAccount={searchAccount}
            toggleDelete={toggleDelete}
            managerLoadAccountList={managerLoadAccountList}
            managerViewRegisterToggleInitSFAccountLoad={managerViewRegisterToggleInitSFAccountLoad}
            managerDeleteError={managerDeleteError}
            managerDeleteLoading={managerDeleteLoading}
            managerAccountCurrentPage={managerAccountCurrentPage}
            managerAccountMaximumPage={managerAccountMaximumPage}
            managerAccountTotalRows={managerAccountTotalRows}
            managerAccountSearchKeyword={managerAccountSearchKeyword}
            managerChangePageAccountList={managerChangePageAccountList}

            managerAccountLoginLoading={managerAccountLoginLoading}
            managerAccountLoginError={managerAccountLoginError}
            managerLoginEvent={managerLoginEvent}

            t={t}
        />)
}
/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */

const ManagerAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerContainerView)

export default ManagerAccountContainer;
