import React from 'react';
import {render} from 'react-dom';
import {Provider as ReduxProvider} from "react-redux";
import {App} from './views/App';
import configureStore from "./state/store";
import {BrowserRouter} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import common_jp from "./translations/jp/common.json";
import common_en from "./translations/en/common.json";
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import storeProvider from "./StoreProvider";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/ja"
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
moment.locale("ja");
/***
 * /////////////////////////////////////
 * コアREACTファイル
 * ------------------
 *
 * reactアプリケーションフローの始まり
 * すべての初期ライブラリがここで読み込まれます。
 * ここで読み込まれるライブラリは
 * * dotenv (環境変数について)
 * * i18next (翻訳について)
 * * Redux (グローバルなステート管理のために)
 * ////////////////////////////////////
 */

// 環境(.env)変数を取得する
require('dotenv').config()

// 翻訳機能も追加。※ デフォルトは日本語
i18next.init({
    interpolation: {escapeValue: false},
    lng: 'jp',
    resources: {
        en: {
            common: common_en
        },
        jp: {
            common: common_jp
        },
    },
});

const configureStores = () => configureStore(window.REDUX_INITIAL_DATA);
storeProvider.init(configureStores)
const store = storeProvider.getStore();

// reactの最初表示を開始する。
render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_API_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_API_CLIENT_ID}
        audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
        redirectUri={window.location.origin + process.env.REACT_APP_AUTH0_API_LOGIN_REDIRECT_PATH}
        useRefreshTokens={true}
        cacheLocation={"localstorage"}
    >
        <ReduxProvider store={store}>
            <I18nextProvider i18n={i18next}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={"ja"}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </MuiPickersUtilsProvider>
            </I18nextProvider>
        </ReduxProvider>
    </Auth0Provider>,
    document.getElementById('root')
);
