/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないserviceContractsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */

export const CANCEL_CONTRACT_REQUEST_API_PATH = "/accounts/{UID}/service_contracts/{SUID}/cancel";
