/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないserviceContractsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */

export const MANAGER_GET_NOTICES_API_PATH = "/manager/notices/";
export const MANAGER_ADD_NOTICES_API_PATH = "/manager/notices/";
export const MANAGER_UPDATE_NOTICE_API_PATH = "/manager/notices/";
export const MANAGER_DELETE_NOTICE_API_PATH = "/manager/notices/";
