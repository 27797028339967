import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import NavBar from './NavBar/NavBar';
import TopBar from './TopBar';
import Contents from "../../components/manager/Contents";
import Footer from "./Footer";

/**
 * このコンポーネントに読み込まれるスタイルは、/theme/managerの設定を使用します。
 */
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 66,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 220
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

/**
 * ナビゲーションバーとサイドバーをロードする
 *
 * @param getProfile
 * @param managerProfile
 * @param sidebarLinks
 * @returns {JSX.Element} | Contents
 * @constructor
 */
const ManagerDashboardLayout = ({getProfile, managerProfile, sidebarLinks}) => {
    const classes = useStyles();
    document.body.style.backgroundColor = "#F2F2F2";
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    return (
        <div className={classes.root}>
            <TopBar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                managerProfile={managerProfile}
            />
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
                sidebarLinks={sidebarLinks}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Contents/>
                        <Footer/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ManagerDashboardLayout;
