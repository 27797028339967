import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

const managerLoadNoticeList = actions.managerLoadNoticeList;

const managerNotificationRegisterToggle = actions.managerNotificationRegisterToggle;
const managerViewNoticeDeleteToggle = actions.managerViewNoticeDeleteToggle;

const managerRegisterNewNotice = actions.managerRegisterNewNotice;
const managerUpdateNotice = actions.managerUpdateNotice;

const managerDeleteNotice = actions.managerDeleteNotice;

const managerNotificationSearchAccount = actions.managerNotificationSearchAccount;
const managerNotificationChooseAccount = actions.managerNotificationChooseAccount;

const managerNotificationSearchAccountSuccess = actions.managerNotificationSearchAccountSuccess

const operations = {
    managerLoadNoticeList,

    managerNotificationRegisterToggle,
    managerViewNoticeDeleteToggle,

    managerRegisterNewNotice,
    managerUpdateNotice,

    managerDeleteNotice,

    managerNotificationSearchAccount,
    managerNotificationChooseAccount,

    managerNotificationSearchAccountSuccess
};
export default operations;
