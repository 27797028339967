import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../theme/globalTheme";
import {currencyFormatter} from "../../../../../../state/utils/Common";

export const ContentsRow = (
    {
        content,
        isBasePlan = false,
        basePlanName = "",
    }) => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={content.uuid} style={{
            paddingLeft: 10,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 45,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={10}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    <Typography variant="body1" style={{paddingLeft: 15}}>
                        {isBasePlan &&
                            basePlanName
                        }
                        {content.product_rate_plan_name}
                        {content?.tiers.length > 1 && content.tiers.filter((item) => { return item.ending_unit === content.quantity}).length > 0 &&
                            <span style={{paddingLeft:15}}>
                                {content?.quantity.toLocaleString()} 件
                            </span>
                        }
                    </Typography>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {currencyFormatter(content.price_per_month)}
                </Typography>
            </Grid>
        </Grid>
    )
}
