/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（requests）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const RequestWatcherSagas = Object.values(watchersSagas);
export {default as requestSelectors} from "./selectors";
export {default as requestOperations} from "./operations";
export {default as requestTypes} from "./types";
export {default as requestForms} from "./forms"

export {requestState, default as requestReducer} from './reducers';
