import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {empty} from "../../state/utils/Common";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {}

/**
 * 編集可能チェック
 *
 * @returns {JSX.Element}
 * @constructor
 */
const _editableCheck = (
    {
        managerProfile,
        children
    }
) => {
    if(!empty(managerProfile) && managerProfile?.editable !== false) {
        return (children)
    }
    return (<></>)
};

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const EditableCheck = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_editableCheck)

export default EditableCheck;
