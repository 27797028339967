import {call, put} from "redux-saga/effects";
import {error_raise} from "../../../root/actions";
import {
    getCancelContractReasonsFailed,
    getCancelContractReasonsLoading,
    getCancelContractReasonsSuccess,
    sendCancelServiceContractFailed,
    sendCancelServiceContractLoading,
    sendCancelServiceContractSuccess
} from "./actions";
import {cancelServiceContractAPI, getCancelServiceReasonsAPI} from "./api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * 契約の解約理由の取得
 * @param data
 * */
export function* getCancelReasons(data) {
    yield put(getCancelContractReasonsLoading())
    try {
        const cancelReasons = yield call(getCancelServiceReasonsAPI, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId);
        yield put(getCancelContractReasonsSuccess(cancelReasons?.data));
    } catch (e) {
        yield put(getCancelContractReasonsFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}


/**
 * 契約解約リクエスト
 * @param data
 */
export function* sendCancelRequest(data) {
    yield put(sendCancelServiceContractLoading())
    try {
        yield call(cancelServiceContractAPI, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId, data.payload.form);
        yield call(data.meta.callBackAction);
        yield put(sendCancelServiceContractSuccess());
    } catch (e) {
        yield put(sendCancelServiceContractFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
