/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないmanagerUsersに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */

export const MANAGER_USERS_API_PATH = '/manager/users/';
export const MANAGER_UNLOCK_AUTH0_USER_API_PATH = "/manager/users/{UUID}/unlock";
export const MANAGER_RESEND_AUTH0_USER_VERIFY_API_PATH = "/manager/users/{UUID}/verify";
