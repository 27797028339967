import React from 'react';
import {Field, Form} from 'react-final-form';
import {Box, Button, FormLabel, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import globalTheme from "../../../../theme/globalTheme";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import FinalFormTextArea from "../../../../atoms/FinalFormTextArea";
import {Radio} from "final-form-material-ui";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {mangerNoticeStoreValidation} from "../../../../../state/ducks/manager/managerNotices/validation";
import {managerNoticeForms} from "../../../../../state/ducks/manager/managerNotices";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import FinalFormAutoSuggest from "../../../../atoms/FinalFormAutoSuggest";
import FinalFormFieldIndependentError from "../../../../atoms/FinalFormFieldIndependentError";
import RestrictSales from "../../../../atoms/RestrictSales";
import FinalFormDate from "../../../../atoms/FinalFormDate";

/**
 * モーダルコンポーネントのスタイル
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * ユーザーの作成および更新アクションのためのユーザーモーダル。
 *
 * @param className - コンポーネントのスタイル
 * @param {boolean} isOpen - "モーダルが開いているか？"フラグ
 * @param {*} toggleEvent - 表示・非表示スウィッチ
 * @param {*} t -  翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const NoticeModal = (
    {
        isOpen,
        toggleEvent,
        registerLoading,
        managerRegisterNewNotice,
        managerUpdateNotice,
        activeNotice,

        managerSearchAccountLoading,
        managerSearchAccountResult,
        managerNotificationSearchAccount,

        managerNotificationChooseAccount,
        managerSelectedAccount,
        managerSearchKeyword,

        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // グローバルテーマオブジェクトのロード
    const globalStyle = globalTheme._default();
    // auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={toggleEvent}
               disableBackdropClick
               className={`${registerLoading ? globalStyle.loading : undefined} `}
        >
            <div className={`${globalStyle.modal} ${globalStyle.modal_800} `} style={modalStyle}>
                <Typography variant="h2" style={{'paddingBottom': 30}}
                            id="simple-modal-title">

                    {!empty(activeNotice) ? t('notices.modal.edit') : t('notices.modal.title')}</Typography>
                <Form
                    onSubmit={e => {
                        e = mergeWithDefaultForm(e, managerNoticeForms.createEditForm)
                        if (e?.type === "1") {
                            e.account_id = null
                        }
                        // アカウントの更新
                        if (!empty(activeNotice)) {
                            if (!registerLoading) {
                                (async () => {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    managerUpdateNotice(activeNotice, e, access_token);
                                })()
                            }
                        } else {
                            if (!registerLoading) {
                                (async () => {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    managerRegisterNewNotice(e, access_token);
                                })()
                            }
                        }
                    }}
                    // ここでフォームデータをバリデーションしてから、キーを翻訳します
                    validate={e => {
                        return translate(t, Validation({...e}, mangerNoticeStoreValidation))
                    }}
                    // ここでフォームの初期値を扱う
                    initialValues={{
                        title: (!empty(activeNotice) ? activeNotice.title : ""),
                        detail: (!empty(activeNotice) ? activeNotice.detail : ""),
                        start_at: (!empty(activeNotice) ? activeNotice.start_at : ""),
                        end_at: (!empty(activeNotice) ? activeNotice.end_at : ""),
                        type: (!empty(activeNotice?.account) ? "2" : "1"),
                        account_id: (!empty(activeNotice)) ? activeNotice.account_id : null,
                    }}
                    // ここでは、フォームのレンダリングと制御を行います。
                    // エラー処理やダブルクリック防止などはここで行います。
                    render={({handleSubmit, form, submitting, pristine, invalid, values}) => (

                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={1}>
                                <Grid item xs={7} style={{borderRight: "1px solid #CCCCCC", paddingRight: 20}}>
                                    <Field
                                        fullWidth
                                        style={{marginBottom: 15}}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        size={"small"}
                                        name="title"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('notices.modal.subject')}
                                    />
                                    <Field
                                        fullWidth
                                        name="detail"
                                        rows={27}
                                        loadingOnDisable={registerLoading}
                                        disabled={registerLoading}
                                        component={FinalFormTextArea}
                                        type="text"
                                        label={t('notices.modal.contents')}
                                    />
                                </Grid>
                                <Grid item xs={5} style={{paddingLeft: 20}}>
                                    <Grid container style={{paddingBottom: 20}}>
                                        <Grid item md={6}
                                              style={{display: "flex"}}
                                        >
                                            <Field
                                                style={{width: 140}}
                                                loadingOnDisable={registerLoading}
                                                disabled={registerLoading}
                                                size={"small"}
                                                name="start_at"
                                                component={FinalFormDate}
                                                type="text"
                                                label={t('notices.modal.public_duration')}
                                            />
                                        </Grid>
                                        <Grid item md={1}
                                              style={{
                                                  marginTop: 35,
                                                  paddingBottom: 3,
                                                  paddingLeft: 3
                                              }}
                                        >
                                            ~
                                        </Grid>
                                        <Grid item md={5}>
                                            <Field
                                                style={{width: 140}}
                                                loadingOnDisable={registerLoading}
                                                disabled={registerLoading}
                                                size={"small"}
                                                name="end_at"
                                                component={FinalFormDate}
                                                type="text"
                                                label={"　"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <p className={""}><FormLabel>
                                        公開アカウント
                                    </FormLabel></p>
                                    <Box>
                                        <label>
                                            <Field
                                                name="type"
                                                type="radio"
                                                component={Radio}
                                                className={globalStyle.radioColor}
                                                value="1"
                                            >

                                            </Field>{" "}
                                            {t('notification.manager.options.all_accounts')}
                                        </label>
                                    </Box>
                                    <Box>
                                        <label>
                                            <Field
                                                name="type"
                                                type="radio"
                                                component={Radio}
                                                className={globalStyle.radioColor}
                                                value="2"
                                            >
                                            </Field>{" "}
                                            <Field name={"account_id"}
                                                   loadingOnDisable={registerLoading}
                                                   disabled={registerLoading || values.type !== '2'}
                                                   size={"small"}
                                                   type="text"
                                                   loading={managerSearchAccountLoading}
                                                   component={FinalFormAutoSuggest}
                                                   styleOverride={{
                                                       display: "inline-block",
                                                       width: 220
                                                   }}
                                                   onKeyUp={(e) => {
                                                       (async () => {
                                                           if (e.key === 'Enter') {
                                                               const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                                   () => {
                                                                       return getAccessTokenSilently();
                                                                   },
                                                               );
                                                               return managerNotificationSearchAccount(access_token, e.target.value);
                                                           }
                                                       })()

                                                   }}
                                                   options={managerSearchAccountResult}
                                            />
                                            <Box marginLeft={6} marginTop={"-13px"}>
                                                <FinalFormFieldIndependentError name={"account_id"} forceShow={true}/>
                                            </Box>
                                        </label>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3} justify="flex-end">
                                <Grid item>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        variant="contained"
                                        disabled={registerLoading}
                                        onClick={() => toggleEvent({})}
                                    >
                                        {t('actions.cancel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <RestrictSales>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={invalid || pristine || registerLoading}
                                        >
                                            {registerLoading ? t('actions.sending') : !empty(activeNotice) ? t('actions.change') : t('actions.add')}
                                        </Button>
                                    </RestrictSales>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </div>

        </Modal>
    )
};

export default NoticeModal;
