import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import TransferConfirmPage from "../../../components/guest/transfer/TransferConfirmPage";
import {transferApproveOperations} from "../../../../state/ducks/guest/transfer";
import {useEffect} from "react";
import {useParams} from "react-router";
import TransferAcceptCompletePage from "../../../components/guest/transfer/TransferAcceptCompletePage";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        transferApproveLoading: state.transferApprove.transferApproveLoading,
        transferApproveError: state.transferApprove.transferApproveError,
        transferApproveData: state.transferApprove.transferApproveData,

        transferApproveSendLoading: state.transferApprove.transferApproveSendLoading,
        transferApproveSendError: state.transferApprove.transferApproveSendError,
        isApproveComplete: state.transferApprove.isApproveComplete
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    approveTransferRequest: transferApproveOperations.approveTransferRequest,
    approveTransferRequestSend: transferApproveOperations.approveTransferRequestSend
}

const _transferConfirmContainer = (
    {
        approveTransferRequest,

        transferApproveLoading,
        transferApproveError,
        transferApproveData,


        transferApproveSendLoading,
        transferApproveSendError,
        isApproveComplete,
        approveTransferRequestSend,

        t
    }) => {

    let {rid} = useParams();

    useEffect(() => {
        return approveTransferRequest(rid);
    }, [approveTransferRequest, rid])

    if (isApproveComplete === false) {
        return (
            <TransferConfirmPage
                t={t}
                loading={transferApproveLoading}
                error={transferApproveError}
                data={transferApproveData}
                transferApproveSendLoading={transferApproveSendLoading}
                transferApproveSendError={transferApproveSendError}
                approveTransferRequestSend={approveTransferRequestSend}
                requestId={rid}
            />)
    } else {
        return (
            <TransferAcceptCompletePage
                t={t}
                loading={transferApproveLoading}
                error={transferApproveError}
                data={transferApproveData}
            />)
    }

}
/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */

const TransferConfirmContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_transferConfirmContainer)

export default TransferConfirmContainer;
