/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないnotificationsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */

export const GET_NOTIFICATIONS_API_PATH = "/accounts/{UID}/notices/"

export const GET_ALL_NOTIFICATIONS_API_PATH = "/accounts/{UID}/notices/all"
