import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

// ユーザー一覧
const managerLoadingUserList = actions.managerLoadingUserList;
const managerFailureUserList = actions.managerFailureUserList;
const managerLoadUserList = actions.managerLoadUserList;
const managerLoadedUserList = actions.managerLoadedUserList;
const managerUserChangePage = actions.managerUserChangePage;

// ユーザー登録モダール
const managerViewRegisterToggle = actions.managerViewRegisterToggle;

// 新規ユーザーを登録
const managerRegisterNewUser = actions.managerRegisterNewUser;

// 新規ユーザー登録
const managerRequestSearchUsers = actions.managerRequestSearchUsers;

// ユーザー削除
const managerViewUserDeleteToggle = actions.managerViewUserDeleteToggle;
const managerDeleteUser = actions.managerDeleteUsers;
const managerDeleteUserLoading = actions.managerDeleteUserLoading;
const managerDeleteUserSuccess = actions.managerDeleteUserSuccess;
const managerDeleteUserFailure = actions.managerDeleteUserFailure;

// ユーザー更新
const managerUpdateUser = actions.managerUpdateUser;

// ブロックされたauth0ユーザーのの解除
const managerUnlockAuth0User =             actions.managerUnlockAuth0User;
const managerUnlockAuth0UserLoading =      actions.managerUnlockAuth0UserLoading;
const managerUnlockAuth0UserSuccess =      actions.managerUnlockAuth0UserSuccess;
const managerUnlockAuth0UserFailed =       actions.managerUnlockAuth0UserFailed;
const managerCloseUnlockAuth0UserModal =   actions.managerCloseUnlockAuth0UserModal;

const managerResendAuth0VerifyMail =                   actions.managerResendAuth0VerifyMail
const managerResendAuth0VerifyMailLoading =            actions.managerResendAuth0VerifyMailLoading
const managerResendAuth0VerifyMailSuccess =            actions.managerResendAuth0VerifyMailSuccess
const managerResendAuth0VerifyMailFailure =            actions.managerResendAuth0VerifyMailFailure
const managerResendAuth0VerifyMailCloseNotification =  actions.managerResendAuth0VerifyMailCloseNotification

const operations = {
    managerLoadingUserList,
    managerFailureUserList,
    managerLoadUserList,
    managerLoadedUserList,
    managerUserChangePage,

    managerViewRegisterToggle,
    managerRegisterNewUser,
    managerUpdateUser,
    managerRequestSearchUsers,

    managerViewUserDeleteToggle,
    managerDeleteUser,
    managerDeleteUserLoading,
    managerDeleteUserSuccess,
    managerDeleteUserFailure,

    managerUnlockAuth0User,
    managerUnlockAuth0UserLoading,
    managerUnlockAuth0UserSuccess,
    managerUnlockAuth0UserFailed,
    managerCloseUnlockAuth0UserModal,

    managerResendAuth0VerifyMail,
    managerResendAuth0VerifyMailLoading,
    managerResendAuth0VerifyMailSuccess,
    managerResendAuth0VerifyMailFailure,
    managerResendAuth0VerifyMailCloseNotification
};
export default operations;
