import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../Page";
import {Link} from "react-router-dom";

import RequestsList from "./list/RequestsList";
import ManagerSearchContainer from "../../../containers/manager/requests/search/ManagerSearchContainer";
import {GetAppRounded} from "@material-ui/icons";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import managerUrlLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param searchFieldOpen
 * @param searchFieldAnchorObject
 * @param {Object} error - ページエラーの詳細
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestsView = (
    {
        managerLoadNoticeList,
        searchFieldOpen,
        managerProfile,
        anchorEl,
        searchFieldCloseEvent,
        searchFieldAnchorObject,
        managerLoadRequestSearch,
        requestListLoading,
        requestList,

        managerRequestsCurrentPage,
        managerRequestsMaximumPage,
        managerRequestsTotalRows,
        managerChangePageAccountList,

        downloadRequestsListLoading,
        downloadRequestListError,
        downloadRequestList,
        getAccessTokenSilently,
        searchFormInformation,
        t,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={1} md={1}>
                        <Typography variant="h2">{t('requests.title')}</Typography>
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <ManagerSearchContainer/>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={4} md={4}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb" color={"#000000"}>
                            <Link to={managerUrlLinks.navigation.requests.index.href}>
                                <Typography color="textPrimary">{t('requests.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={8} style={{textAlign: "right", marginTop: "-10px"}}>
                        <Button type={"button"} variant={"text"} disabled={downloadRequestsListLoading}
                                onClick={async () => {
                                    if (!empty(managerProfile)) {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        await downloadRequestList(access_token, searchFormInformation);
                                    }
                                }}
                                style={{
                                    border: "none",
                                    fontSize: 11
                                }}><GetAppRounded/> {t('requests.manager.download_requests')}</Button>
                    </Grid>
                </Grid>
                <Grid container alignItems={"flex-end"} alignContent={"flex-end"} justify={"flex-end"}>
                    <Grid item>
                        {downloadRequestsListLoading === true &&
                        <LinearProgress color={"primary"} style={{width: 140, marginRight:10, marginTop:"-5px", float: "right"}} />
                        }
                    </Grid>
                </Grid>
                <Box mt={1}>
                    {/* ユーザー一覧 */}
                    <RequestsList
                        requestListLoading={requestListLoading}
                        requests={requestList}
                        managerRequestsCurrentPage={managerRequestsCurrentPage}
                        managerRequestsMaximumPage={managerRequestsMaximumPage}
                        managerRequestsTotalRows={managerRequestsTotalRows}
                        changePage={managerChangePageAccountList}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
}

export default RequestsView;
