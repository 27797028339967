import axios from "axios";
import storeProvider from '../../StoreProvider'
import {error_raise} from "../ducks/root/actions";
import {empty, stackTracer} from "./Common";
import {Validator} from "jsonschema";
/***
 * ///////////////////////////////////////////////
 * APIユーティリティー  (Axiosを使用)
 * -------------------------------
 *
 * APIを呼び出すためのユーティリティクラス
 * authorizationやcontent-typeなどのデフォルトヘッダーのプリセット
 *
 * RESTのデフォルト(GET, POST, DELETE, PUT)はここで呼び出されます。
 * @dependencies https://github.com/axios/axios
 * //////////////////////////////////////////////
 */

/**
 * reactフレームワークの外で環境変数を呼び出す
 */
require('dotenv').config()
let accessTokens = "";

/**
 * プリリクエストの設定はこちら
 */
axios.interceptors.request.use(async function (config) {
    if (
        config.url === '/error' || (storeProvider.getStore().getState().root.isError === false &&
            storeProvider.getStore().getState().root.accessTokenFailureDialog === false)
    ) {
        return config;
    }
    return Promise.reject("以前のシステムエラーが発生されました。")
});

const systemErrorCodes = [500]
const systemErrorMessage = ["E403_ILLEGAL_HEADERS", "E403_ILLEGAL_OPERATOR"]

/**
 * システムエラーチェック
 * @param response
 * @returns {boolean}
 */
function isSystemError(response) {
    return systemErrorCodes.includes(response.status) || systemErrorMessage.includes(response.data?.code);
}

const splitErrorMessage = ["E409_USER_ALREADY_EXISTS"]

/**
 * ログインユーザーの種類に応じてエラーメッセージを分けるチェック
 * @param response
 * @returns {boolean}
 */
function isGroupSpecificError(response) {
    return splitErrorMessage.includes(response.data?.code)
}


/**
 * レスポンス デフォルトのインターセプター
 */
axios.interceptors.response.use((response) => {
    if (!empty(response?.config?.schema)) {
        let responseResult;
        responseResult = new Validator().validate(response.data, response.config.schema)
        if (responseResult.valid === false && !empty(responseResult?.errors[0]) && (typeof accessTokens === 'string' || accessTokens instanceof String)) {
            const errorStack = {
                type: "badResponseSchema",
                instance: responseResult.instance,
                url: response.config.url,
                schema: responseResult?.errors[0].schema,
                reason: responseResult?.errors[0].stack,
                stack: response.config.stack,
                translation_error_key: "無効なレスポンス"
            }
            storeProvider.getStore().dispatch(
                error_raise(
                    errorStack
                )
            );
            return Promise.reject(errorStack)
        }
    }
    return response;
}, (error) => {
    let userType = "";
    if (error.response) {
        // システムエラーのリスポンスがあった場合
        if (isSystemError(error.response)) {
            const state = storeProvider.getStore().getState();
            if (!state.root.isError) {
                storeProvider.getStore().dispatch(error_raise({
                    title: error.response.status,
                    translation_error_key: `error_codes.${error.response.status}.${error.response.data?.code}`
                }))
            }
        }
        // ログインユーザーの種類に応じてエラーメッセージを分ける場合
        if (isGroupSpecificError(error.response)) {
            const state = storeProvider.getStore().getState();
            userType = state.root.managerProfile?.is_user === false ? "ADMIN." : "USER.";
        }
        return Promise.reject({
            message: error.response.data,
            http_code: error.response.status,
            response_headers: error.response.headers,
            translation_error_key: `error_codes.${error.response.status}.${userType}${error.response.data?.code}`
        })
    } else if (error.request) {
        return Promise.reject({
            message: error.request,
            title: "ERROR_CODE: NETWORK_ERROR",
            http_code: "NETWORK_ERROR",
            response_headers: null,
            request: error.request,
            translation_error_key: `error_codes.OFFLINE.NETWORK_ERROR`
        })
    }
    return Promise.reject({
        message: error.message,
        title: "ERROR_CODE: UNKNOWN",
        http_code: "UNKNOWN",
        response_headers: null,
        translation_error_key: `error_codes.UNKNOWN.INTERRUPTED`
    })
})

export default class ApiUtils {
    /**
     * constructor
     * @param {string|null} accessToken auth0 アクセストークン
     * @param {number|null} timeout タイムアウト
     * @param {string|null} baseurl カスタムURL
     * @param {boolean} forceNoMock ロカール環境でモック設定を有効にしても、モックサーバーに送信しない。
     */
    constructor(accessToken, timeout = null, baseurl = null, forceNoMock = false,) {
        accessTokens = accessToken;
        axios.defaults.baseURL = baseurl || process.env.REACT_APP_MANAGEMENT_API_DOMAIN;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.post['Accept'] = 'application/json';
        if (!empty(process.env.REACT_APP_MOCK_MANAGEMENT_API_DOMAIN) && forceNoMock === false) {
            const state = storeProvider.getStore().getState();
            const debug_response = state.root.debug_response
            if (!empty(debug_response)) {
                axios.defaults.baseURL = process.env.REACT_APP_MOCK_MANAGEMENT_API_DOMAIN
                axios.defaults.headers.common['Prefer'] = 'code=' + debug_response
            }
        }

        if (accessToken !== null) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
        }
        axios.defaults.timeout = timeout || process.env.REACT_APP_MANGEMENT_API_TIMEOUT;
    }

    /**
     * GET リクエスト
     * @param uri API アクセスポイント
     * @param parameters Get クエリーパラメーター
     * @param responseSchema
     * @returns {AxiosPromise} (API|Network) リクエスト
     */
    get(uri, parameters, responseSchema = null, additionalParams = {}) {
        return axios({
            ...additionalParams,
            method: 'GET',
            url: uri,
            params: {
                ...parameters,
            },
            schema: responseSchema,
            stack: stackTracer()
        });
    }

    /**
     * POST リクエスト
     * @param {string} uri API アクセスポイント
     * @param {Object} body Post データ
     * @param responseSchema
     * @returns {AxiosPromise} (API|Network) リスポンス
     */
    post(uri, body, responseSchema = null) {
        return axios({
            method: 'POST',
            url: uri,
            data: JSON.stringify(body),
            schema: responseSchema,
            stack: stackTracer()
        });
    }

    /**
     * DELETE リクエスト
     * @param {string} uri API アクセスポイント
     * @param {string} id ID
     * @returns {AxiosPromise} (API|Network) リスポンス
     */
    delete(uri, id) {
        return axios({
            method: 'DELETE',
            url: uri + id,
        });
    }

    /**
     * UPDATE リクエスト
     * @param uri API アクセスポイント
     * @param id Unique ID
     * @param body 更新データ
     * @param responseSchema
     * @returns {AxiosPromise} (API|Network) リスポンス
     */
    put(uri, id, body, responseSchema  = null) {
        return axios({
            method: 'PUT',
            url: uri + id,
            data: body,
            schema: responseSchema,
            stack: stackTracer()
        });
    }
}

export {axios};
