import React from 'react';
import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import AccountsList from "./list/AccountsList";
import Toolbar from "./Toolbar";
import {ConfirmationModal} from "../../../atoms/ConfirmationModal";
import ManagerAccountEdit from "./modal/Edit";
import ManagerCreateAccountContainer from "../../../containers/manager/accounts/modal/CreateContainer";
import RestrictSales from "../../../atoms/RestrictSales";

/**
 * コンポーネントスタイルクラス
 *
 * @type {(props?: any) => ClassNameMap<"button"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * 管理者側のアカウント一覧のメインファイル
 * @param {boolean} reLoad - コンポーネントの再読み込みチェック
 * @param {Object} managerProfile - ログインしたユーザーの情報
 * @param {string|number} currentPage - 現在のページ
 * @param {Object} accounts - 取得したアカウント一覧データ
 * @param {boolean} managerAccountIsEditView - アカウント編集の開閉状態
 * @param {function} managerEditAccount - アカウント編集アクション
 * @param {Object} managerAccountRegisterError - 新規アカウント登録エラー
 * @param {boolean} managerAccountRegisterLoading - 新規アカウントの登録ロード状態
 * @param {boolean} managerLoading - コンポーネントの最初ロード状態
 * @param {boolean} deleteView - アカウント削除の開閉状態
 * @param {Object} activeId - (編集・削除のみ) 選択したユーザー情報
 * @param {function} toggleRegisterView - 登録モーダルの開閉状態
 * @param {function} managerViewEditToggle - 編集モーダルの開閉状態
 * @param {function} managerDeleteAccount - ユーザー削除リクエスト
 * @param {function} searchAccount - 検索リクエスト
 * @param {boolean} toggleDelete - 削除モーダルの開閉状態
 * @param {function} managerLoadAccountList - アカウント一覧ロード状態
 * @param {function} managerViewRegisterToggleInitSFAccountLoad - SFアカウントロード状態
 * @param {Object} managerDeleteError - 削除エラー内容
 * @param {boolean} managerDeleteLoading - 削除実行のロード中
 * @param t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountView = (
    {
        reLoad,
        managerProfile,

        accounts,
        managerAccountIsEditView,
        managerEditAccount,
        managerAccountRegisterError,
        managerAccountRegisterLoading,
        managerLoading,
        deleteView,
        activeId,
        toggleRegisterView,
        managerViewEditToggle,
        managerDeleteAccount,
        managerRequestChangeKSearchKeyWord,
        managerAccountVisibleKeyword,
        searchAccount,
        toggleDelete,
        managerLoadAccountList,
        managerViewRegisterToggleInitSFAccountLoad,
        managerDeleteError,
        managerDeleteLoading,
        getAccessTokenSilently,

        managerAccountCurrentPage,
        managerAccountMaximumPage,
        managerAccountTotalRows,
        managerAccountSearchKeyword,
        managerChangePageAccountList,

        managerAccountLoginLoading,
        managerAccountLoginError,
        managerLoginEvent,

        t

    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('accounts.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('accounts.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <RestrictSales>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => {
                                    (async () => {
                                        const access_token = await getAccessTokenSilently()
                                        managerViewRegisterToggleInitSFAccountLoad(access_token)
                                    })()
                                }}
                            >
                                {t('accounts.new_account')}
                            </Button>
                        </RestrictSales>
                    </Grid>
                    <ConfirmationModal
                        isOpen={deleteView}
                        setClose={toggleDelete}
                        confirmationTitle={t('confirmation.delete.title')}
                        confirmationDescription={t('confirmation.delete.description')}
                        buttonText={t('actions.delete')}
                        event={managerDeleteAccount}
                        id={activeId}
                        isLoading={managerDeleteLoading}
                        loadingText={t('actions.sending')}
                        deleteInformation={t('accounts.delete_text', {account: activeId?.name})}
                        cancelText={t('actions.cancel')}
                        error={t(managerDeleteError?.translation_error_key)}
                    />
                    <ManagerAccountEdit
                        isOpen={managerAccountIsEditView}
                        setClose={managerViewEditToggle}
                        editEvent={managerEditAccount}
                        activeAccount={activeId}
                        error={managerAccountRegisterError}
                        loading={managerAccountRegisterLoading}
                        t={t}
                    />
                    <Grid container item xs={12} md={12} justify="flex-start">
                        <Toolbar searchAccount={searchAccount}
                                 managerRequestChangeKSearchKeyWord={managerRequestChangeKSearchKeyWord}
                                 managerAccountSearchKeyword={managerAccountSearchKeyword}
                                 managerAccountVisibleKeyword={managerAccountVisibleKeyword}
                                 t={t}/>
                    </Grid>
                </Grid>
                <ManagerCreateAccountContainer/>
                <Box mt={3}>
                    <AccountsList
                        maximumPage={managerAccountMaximumPage}
                        changePage={managerChangePageAccountList}
                        totalRows={managerAccountTotalRows}
                        currentPage={managerAccountCurrentPage}
                        accounts={accounts}
                        managerLoading={managerLoading}
                        toggleDelete={toggleDelete}
                        managerViewEditToggle={managerViewEditToggle}
                        managerAccountSearchKeyword={managerAccountSearchKeyword}
                        managerAccountLoginLoading={managerAccountLoginLoading}
                        managerAccountLoginError={managerAccountLoginError}
                        managerLoginEvent={managerLoginEvent}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ManagerAccountView;
