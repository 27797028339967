/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないserviceContractsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */

/**
 * お客様が見える限りすべてのプラン
 */
export const GET_SERVICE_CONTRACT_DATA_API_PATH = "/accounts/{UID}/service_contracts/{SUID}"
export const GET_SERVICE_CONTRACT_VIEWABLE_PRODUCTS_API_PATH = "/accounts/{UID}/service_contracts/{SUID}/products";
export const PUT_PLAN_UPDATE_REQUEST_API_PATH = "/accounts/{UID}/service_contracts/{SUID}/change";
