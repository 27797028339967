import {takeEvery, takeLeading} from 'redux-saga/effects';
import {
    addUserList,
    deleteUser,
    filterUserList,
    resendAuth0VerificationEmail,
    selectUserList,
    unblockAuth0User,
    updateUser
} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchUsers() {
    yield takeLeading(types.REQUEST_USERS, selectUserList);
    yield takeEvery(types.ADD_USER, addUserList)
    yield takeEvery(types.UPDATE_USER, updateUser)
    yield takeEvery(types.DELETE_USER, deleteUser)
    yield takeEvery(types.SEARCH_USER_REQUEST, filterUserList)
    yield takeEvery(types.AUTH0_UNLOCK_USER, unblockAuth0User)
    yield takeEvery(types.AUTH0_RESEND_VERIFY_EMAIL, resendAuth0VerificationEmail)
}
