import React from 'react';
import {Box, Breadcrumbs, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import {Link} from "react-router-dom";
import urlLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const CompletePage = (
    {
        t,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.title_user')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('contact.title')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.contact.index.href}>
                                <Typography color="textPrimary">{t('contact.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography variant={"h4"}>{t('contact.contact_form')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Typography style={{marginBottom: 20, whiteSpace: "pre-wrap"}}
                                        variant={"body1"}>{t('contact.complete.description')}</Typography>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default CompletePage;
