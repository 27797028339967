import React from 'react';
import {Box, Breadcrumbs, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import NotificationsList from "../list/NotificationsList";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

/**
 * /notifications コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 * お知らせ一覧画面コンポーネント
 *
 * @param {boolean} loading - お知らせ一覧のロード状態
 * @param {Object} activeProfile - ローグインユーザー
 * @param {Object} profile - ログインユーザープロフィール。
 * @param {boolean} reLoad - double load checker - ダブルクリック対策
 * @param {Object} notifications - 現在のユーザー
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const AllNotificationView = (
    {
        t,
        loading,
        notifications,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('notification.title')}
        >
            <Container maxWidth={false}>
                <Box>
                    <Box paddingBottom={2}>
                        <Grid style={{paddingBottom: 0}} container>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h2">{t('notification.title')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignContent={"center"} alignItems={"center"}>
                            <Grid item xs={12} md={12}>
                                <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                    <Link to={urlLinks.navigation.top.index.href}>
                                        <Typography color="textPrimary">{t('general.top')}</Typography>
                                    </Link>
                                    <Link to={urlLinks.navigation.notifications.index.sub_links.past.href}>
                                        <Typography style={{paddingTop: 6, color: "#000000"}}
                                                    variant={"subtitle1"}>{t('notification.all.breadcrumb')}</Typography>
                                    </Link>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* お知らせ一覧 */}
                    <NotificationsList
                        loading={loading}
                        notifications={notifications}
                        t={t}/>
                </Box>
            </Container>
        </Page>
    );
};

export default AllNotificationView;
