import globalTheme from "../theme/globalTheme";
import {Field} from "react-final-form";

const FinalFormFieldIndependentError = ({name, forceShow = false}) => {
    const defaultFields = globalTheme._default();
    return (<Field
        name={name}
        className={defaultFields.error}
        subscription={{touched: true, error: true}}
        render={({meta: {touched, error}}) => {
            if (forceShow) {
                touched = true
            }
            return touched && error ?
                <span className={defaultFields.error} style={{fontSize: "0.6964285714285714rem"}}>{error}</span> : null
        }
        }
    />)
}
export default FinalFormFieldIndependentError
