import {managerUserListOperations} from "../../../../state/ducks/manager/managerUsers";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import UserView from "../../../components/manager/users/Page";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import userRoles from "../../../../state/utils/data/application_manager_user_roles.json"
import {rootOperations} from "../../../../state/ducks/root";
import RestrictSales from "../../../atoms/RestrictSales";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        managerProfile: state.root.managerProfile,

        managerLoading: state.managerUser.managerLoading,
        managerUsers: state.managerUser.managerUserList,
        managerUserList_CurrentPage: state.managerUser.managerUserList_CurrentPage,

        managerIsRegisterView: state.managerUser.managerIsRegisterView,

        managerSearchLoad: state.managerUser.managerSearchLoad,
        managerSearchUserResult: state.managerUser.managerSearchUserResult,
        managerError: state.managerUser.managerError,

        managerRegisterLoading: state.managerUser.managerRegisterLoading,
        managerRegisterError: state.managerUser.managerRegisterError,

        managerActiveId: state.managerUser.managerActiveId,
        managerDeleteUserView: state.managerUser.managerDeleteUserView,
        managerDeleteLoading: state.managerUser.managerDeleteLoading,
        managerDeleteError: state.managerUser.managerDeleteError,

        managerUnblockNotificationShow: state.managerUser.managerUnblockNotificationShow,
        managerResendNotificationShow: state.managerUser.managerResendNotificationShow
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    managerViewRegisterToggle: managerUserListOperations.managerViewRegisterToggle,
    managerRegisterNewUser: managerUserListOperations.managerRegisterNewUser,
    managerRequestSearchUsers: managerUserListOperations.managerRequestSearchUsers,
    managerUserChangePage: managerUserListOperations.managerUserChangePage,
    managerViewUserDeleteToggle: managerUserListOperations.managerViewUserDeleteToggle,
    managerDeleteUser: managerUserListOperations.managerDeleteUser,
    managerUpdateUser: managerUserListOperations.managerUpdateUser,
    managerLoadUserList: managerUserListOperations.managerLoadUserList,

    managerUnlockAuth0User: managerUserListOperations.managerUnlockAuth0User,
    managerCloseUnlockAuth0UserModal: managerUserListOperations.managerCloseUnlockAuth0UserModal,

    managerResendAuth0VerifyMail: managerUserListOperations.managerResendAuth0VerifyMail,
    managerResendAuth0VerifyMailCloseNotification: managerUserListOperations.managerResendAuth0VerifyMailCloseNotification
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

const _managerUserAccountContainer = (
    {
        error,
        reLoad,
        managerUsers,
        managerLoading,
        managerIsRegisterView,
        managerProfile,
        managerViewRegisterToggle,
        managerRegisterNewUser,
        managerSearchLoad,
        managerSearchUserResult,
        managerRequestSearchUsers,
        managerRegisterLoading,
        managerRegisterError,
        managerActiveId,
        managerDeleteUserView,
        managerDeleteLoading,
        managerDeleteError,
        managerViewUserDeleteToggle,
        managerDeleteUser,
        managerUpdateUser,
        managerLoadUserList,
        loadSidebarItems,
        managerUnblockNotificationShow,
        managerResendNotificationShow,
        managerUnlockAuth0User,
        managerCloseUnlockAuth0UserModal,
        managerResendAuth0VerifyMail,
        managerResendAuth0VerifyMailCloseNotification,
        t,
    }
) => {
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();

    /**
     * 初回ロード時に、APIからユーザーリストを呼び出す
     */
    useEffect(() => {
        if (!empty(managerProfile) && reLoad) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                managerLoadUserList(access_token);
            })()
        }
    }, [managerLoadUserList, getAccessTokenSilently, managerProfile, reLoad])

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.users)
    }, [loadSidebarItems])

    return (
        <RestrictSales>
            <UserView
                error={error}
                reLoad={reLoad}
                managerUsers={managerUsers}
                managerLoading={managerLoading}
                managerIsRegisterView={managerIsRegisterView}
                managerProfile={managerProfile}
                managerViewRegisterToggle={managerViewRegisterToggle}
                managerRegisterNewUser={managerRegisterNewUser}
                managerSearchLoad={managerSearchLoad}
                managerSearchUserResult={managerSearchUserResult}
                managerRequestSearchUsers={managerRequestSearchUsers}
                managerRegisterLoading={managerRegisterLoading}
                managerRegisterError={managerRegisterError}
                managerActiveId={managerActiveId}
                managerDeleteUserView={managerDeleteUserView}
                managerDeleteLoading={managerDeleteLoading}
                managerDeleteError={managerDeleteError}
                managerViewUserDeleteToggle={managerViewUserDeleteToggle}
                managerDeleteUser={managerDeleteUser}
                managerUpdateUser={managerUpdateUser}
                managerUnblockNotificationShow={managerUnblockNotificationShow}
                managerResendNotificationShow={managerResendNotificationShow}
                managerUnlockAuth0User={managerUnlockAuth0User}
                managerCloseUnlockAuth0UserModal={managerCloseUnlockAuth0UserModal}
                managerResendAuth0VerifyMail={managerResendAuth0VerifyMail}
                managerResendAuth0VerifyMailCloseNotification={managerResendAuth0VerifyMailCloseNotification}
                userRoles={userRoles.user_roles}
                t={t}
            />
        </RestrictSales>
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerUserAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerUserAccountContainer)

export default ManagerUserAccountContainer;
