import {takeLeading} from "redux-saga/effects";
import {default as types} from "./types";
import {getTransferDataSaga, sendTransferRequestSaga} from "./sagas";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */

export function* fetchServiceContractTransfer() {
    yield takeLeading(types.SEND_TRANSFER_FORM_REQUEST, sendTransferRequestSaga);
    yield takeLeading(types.GET_SERVICE_CONTRACT_TRANSFER_DATA, getTransferDataSaga)
}
