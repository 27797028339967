import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているNotificationsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const notificationListState = {
    loading: false,
    error: {},
    notifications: {},

    allNotificationsLoading: false,
    allNotifications: {},
    allNotificationsError: {}
};

export default function notificationList(state = notificationListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // お知らせ一覧取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_NOTIFICATIONS_LOADING:
            return {
                ...state,
                loading: state.loading = true,
                notifications: state.notifications = {},
                error: state.error = {},
            }
        case types.REQUEST_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                notifications: state.notifications = action.payload,
                error: state.error = {},
            }
        case types.REQUEST_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                error: state.error = action.payload.error,
            }
        // -----------------------------------------------
        // 全てのお知らせの一覧取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_ALL_NOTIFICATIONS_LOADING:
            return {
                ...state,
                allNotificationsLoading: state.allNotificationsLoading = true,
                allNotifications: state.allNotifications = {},
                allNotificationsError: state.allNotificationsError = {},
            }
        case types.REQUEST_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                allNotificationsLoading: state.allNotificationsLoading = false,
                allNotifications: state.allNotifications = action.payload,
                allNotificationsError: state.allNotificationsError = {},
            }
        case types.REQUEST_ALL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                allNotificationsLoading: state.allNotificationsLoading = false,
                allNotificationsError: state.allNotificationsError = action.payload.error,
            }
        default:
            return state;
    }
}
