import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../state/ducks/root";
import userSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {useHistory, useParams} from "react-router";
import ContactPage from "../../../components/user/contact/ContactPage";
import {contactOperations} from "../../../../state/ducks/user/contact";
import {empty} from "../../../../state/utils/Common";
import ConfirmPage from "../../../components/user/contact/ConfirmPage";
import CompletePage from "../../../components/user/contact/CompletePage";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        contactEditFormMessage: state.contact.contactEditFormMessage
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    receiveContactFormMessage: contactOperations.receiveContactFormMessage,
    confirmContactRequest: contactOperations.confirmContactRequest
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _contactContainer = (
    {
        managerProfile,
        loadSidebarItems,
        contactEditFormMessage,
        receiveContactFormMessage,
        confirmContactRequest,
        t
    }) => {

    let {action} = useParams();
    const history = useHistory();

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.contact)
    }, [loadSidebarItems])

    if (action === "confirm" && empty(contactEditFormMessage)) {
        history.push('/contact')
        return (<></>)
    }

    const SendSuccessCallBackAction = () => history.push('/contact/complete');

    switch (action) {
        default:
            return (
                <ContactPage
                    t={t}
                    message={contactEditFormMessage}
                    receiveContactFormMessage={receiveContactFormMessage}
                />);
        case ("confirm"):
            return (
                <ConfirmPage t={t} SendSuccessCallBackAction={SendSuccessCallBackAction} managerProfile={managerProfile}
                             confirmContactRequest={confirmContactRequest} message={contactEditFormMessage}/>);
        case("complete"):
            return (<CompletePage t={t}/>);
    }
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const RequestsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_contactContainer)

export default RequestsContainer;
