import {Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";
import {Create, Delete} from "@material-ui/icons";
import RestrictSales from "../../../../atoms/RestrictSales";

export const NoticesListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.notices[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 13,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={3} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.notices[props.index].title}
                </Typography>
            </Grid>
            <Grid item xs={3} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.notices[props.index]?.account?.name}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.notices[props.index].start_at}~{props.data.notices[props.index].end_at}
                </Typography>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <Button
                    variant={"text"}
                    size={"small"}
                    className={commonTheme.listButton}
                    onClick={() => props.data.handleEditClick(props.data.notices[props.index])}
                >
                    <Create style={{
                        fontSize: "inherit",
                        marginRight: 3,
                        marginTop: "-3px"
                    }}/> {props.data.t('notices.list_headers.edit')}
                </Button>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <RestrictSales>
                    <Button
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.handleDeleteClick(props.data.notices[props.index])}
                    >
                        <Delete style={{
                            fontSize: "inherit",
                            marginRight: 3,
                            marginTop: "-3px"
                        }}/> {props.data.t('notices.list_headers.delete')}

                    </Button>
                </RestrictSales>
            </Grid>
        </Grid>
    )
}
