import React from 'react';
import {Box, Breadcrumbs, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";

import RequestsList from "./list/RequestsList";
import SearchContainer from "../../../containers/user/requests/search/SearchContainer";
import urlLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestsView = (
    {
        requestListLoading,
        requestList,
        t,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.title_user')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3} alignContent={"center"} alignItems={"center"} style={{marginBottom: -1}}>
                    <Grid item xs={3} md={3} lg={2} xl={1}>
                        <Typography variant="h2">{t('requests.title_user')}</Typography>
                    </Grid>
                    <Grid item xs={5} lg={6} md={5}>
                        <SearchContainer/>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={4} md={4}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.requests.index.href}>
                                <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    {/* ユーザー一覧 */}
                    <RequestsList
                        requestListLoading={requestListLoading}
                        requests={requestList}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
}

export default RequestsView;
