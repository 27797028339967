/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS'
export const REQUEST_NOTIFICATIONS_LOADING = 'REQUEST_NOTIFICATIONS_LOADING'
export const REQUEST_NOTIFICATIONS_SUCCESS = 'REQUEST_NOTIFICATIONS_SUCCESS'
export const REQUEST_NOTIFICATIONS_FAILURE = 'REQUEST_NOTIFICATIONS_FAILURE'

export const REQUEST_ALL_NOTIFICATIONS = "REQUEST_ALL_NOTIFICATIONS"
export const REQUEST_ALL_NOTIFICATIONS_LOADING = "REQUEST_ALL_NOTIFICATIONS_LOADING"
export const REQUEST_ALL_NOTIFICATIONS_SUCCESS = "REQUEST_ALL_NOTIFICATIONS_SUCCESS"
export const REQUEST_ALL_NOTIFICATIONS_FAILURE = "REQUEST_ALL_NOTIFICATIONS_FAILURE"

const types = {
    REQUEST_NOTIFICATIONS,
    REQUEST_NOTIFICATIONS_LOADING,
    REQUEST_NOTIFICATIONS_SUCCESS,
    REQUEST_NOTIFICATIONS_FAILURE,

    REQUEST_ALL_NOTIFICATIONS,
    REQUEST_ALL_NOTIFICATIONS_LOADING,
    REQUEST_ALL_NOTIFICATIONS_SUCCESS,
    REQUEST_ALL_NOTIFICATIONS_FAILURE
};
export default types;
