import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */
// ユーザー一覧取得
const loadingUserList = actions.loadingUserList;
const failureUserList = actions.failureUserList;
const loadUserList = actions.loadUserList;
const loadedUserList = actions.loadedUserList;

// モーダルの開閉
const toggleRegister = actions.viewRegisterToggle;
const viewUserDeleteToggle = actions.viewUserDeleteToggle;

// 新規ユーザー登録リクエスト
const registerUser = actions.registerNewUser;
const userRegistrationLoad = actions.userRegistrationLoad;

// 新規ユーザー更新リクエスト
const updateUser = actions.updateUser;

// 検索リクエスト
const requestSearchUsers = actions.requestSearchUsers;

// ページネーション
const userChangePage = actions.userChangePage;

// 削除
const deleteUsers = actions.deleteUsers;
const deleteLoading = actions.deleteLoading;
const deleteUserSuccess = actions.deleteUserSuccess;
const deleteUserFailure = actions.deleteUserFailure;

// ブロックされたauth0ユーザーのの解除
const unlockAuth0User = actions.unlockAuth0User;
const unlockAuth0UserLoading = actions.unlockAuth0UserLoading;
const unlockAuth0UserSuccess = actions.unlockAuth0UserSuccess;
const unlockAuth0UserFailed = actions.unlockAuth0UserFailed;
const closeUnlockAuth0UserModal = actions.closeUnlockAuth0UserModal;

const resendAuth0VerifyMail = actions.resendAuth0VerifyMail
const resendAuth0VerifyMailLoading = actions.resendAuth0VerifyMailLoading
const resendAuth0VerifyMailSuccess = actions.resendAuth0VerifyMailSuccess
const resendAuth0VerifyMailFailure = actions.resendAuth0VerifyMailFailure
const resendAuth0VerifyMailCloseNotification = actions.resendAuth0VerifyMailCloseNotification

const operations = {
    loadingUserList,
    failureUserList,
    loadUserList,
    loadedUserList,
    userChangePage,

    toggleRegister,
    userRegistrationLoad,
    registerUser,
    updateUser,
    requestSearchUsers,

    viewUserDeleteToggle,
    deleteLoading,
    deleteUsers,
    deleteUserSuccess,
    deleteUserFailure,

    unlockAuth0User,
    unlockAuth0UserLoading,
    unlockAuth0UserSuccess,
    unlockAuth0UserFailed,
    closeUnlockAuth0UserModal,

    resendAuth0VerifyMail,
    resendAuth0VerifyMailLoading,
    resendAuth0VerifyMailSuccess,
    resendAuth0VerifyMailFailure,
    resendAuth0VerifyMailCloseNotification
};
export default operations;
