/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（requests）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const ContactWatcherSagas = Object.values(watchersSagas);
export {default as contactSelectors} from "./selectors";
export {default as contactOperations} from "./operations";
export {default as contactTypes} from "./types";
export {default as contactForms} from "./forms"

export {contactState, default as contactReducer} from './reducers';
