import {makeStyles} from "@material-ui/core";

/**
 *   オブジェクト型クラスのグローバルテーマ化はここで行われ、これらのスタイルはどのテーマでも使用されます。
 */

/**
 * グローバル・フォーム・スタイリング
 */
const defaultFormStyle = makeStyles((theme) => ({
        chip: {
            backgroundColor: theme.palette.background.required,
            marginLeft: "5px",
            borderRadius: "5px",
            fontWeight: 500,
            color: "#FFFFFF"
        },
        textfield: {
            marginTop: "5px"
        },
        disabledInput: {
            backgroundColor: "#EEEEEE",
            color: "#8F8F8F",
            fontWeight: "bolder!important"
        },

        groupListRounded: {
            "& div.MuiPaper-rounded": {
                borderRadius: 0,
                "&:first-child": {
                    borderTopLeftRadius: "4px !important",
                    borderTopRightRadius: "4px !important",
                },
                "&:last-child": {
                    borderBottomLeftRadius: "4px !important",
                    borderBottomRightRadius: "4px !important",
                }
            }
        },

        selectWidth110: {
            minWidth: 110
        },
        negativeOption: {
            marginTop: 5,
            color: theme.palette.background.negativeOption,
            cursor: "pointer",
            marginLeft: 44
        },
        positiveOption: {
            marginTop: 5,
            color: theme.palette.background.positiveOption,
            cursor: "pointer",
            marginLeft: 44
        },
        toolTip: {
            marginTop: 5,
            color: "#7E7E7E",
            cursor: "pointer",
            marginLeft: 44
        },
        warningButton: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.secondary.main,
            "&:hover": {
                backgroundColor: "#333333"
            }
        },
        loadingOnDisable: {
            cursor: "wait !important",
            "& select": {
                cursor: "wait !important"
            },
            "& textarea": {
                cursor: "wait !important"
            },
            "& input": {
                cursor: "wait !important"
            },
        }
    }),
    {index: 1}
);

/**
 * カードテーマ
 */
const defaultCards = makeStyles((theme) => ({
    headers: {
        borderBottomColor: "#CCC",
        borderBottomWidth: 1,
        height: 50,
        paddingTop: 11,
        paddingBottom: 11,
        borderBottomStyle: "solid",
        marginBottom: 5
    }
}));

const wizard = makeStyles((theme) => ({
    headers: {
        backgroundColor: theme.palette.background.dark,
        paddingTop: 11,
        paddingBottom: 11,
        textAlign: "center",
        zIndex: "2",
        position: "relative",
        '&:not(.active)': {
            '&::before': {},
        },
        '&:last-child': {
            marginRight: "-20px"
        },
        '&.complete': {
            marginLeft: 0,
            zIndex: "6",
            backgroundColor: "#F4B069",
            color: theme.palette.primary.contrastText,
            '&::after': {
                content: "''",
                top: 10,
                right: "-29px",
                position: "absolute",
                borderStyle: "solid",
                transform: "matrix(0, 1, -1, 0, 0, 0)",
                borderWidth: "0 20px 20px 20px",
                borderColor: "transparent transparent #F4B069 transparent",
                background: "#F4B069 0% 0% no-repeat padding-box"
            },
        },
        '&.active': {
            marginLeft: 0,
            zIndex: "5",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&::after': {
                content: "''",
                top: 10,
                right: "-29px",
                position: "absolute",
                borderStyle: "solid",
                transform: "matrix(0, 1, -1, 0, 0, 0)",
                borderWidth: "0 20px 20px 20px",
                borderColor: "transparent transparent #EF7C04 transparent",
                background: "#F4B069 0% 0% no-repeat padding-box"
            },
        }
    }
}));

/**
 * ディフォルトテーマオブジェクト
 * @private
 */
export const _default = makeStyles((theme) => ({
    link: {
        color: "#0D66D0",
        cursor: "pointer"
    },
    radioColor: {
        color: theme.palette.primary.main + " !important"
    },
    border: {
        border: "1px #CCC solid"
    },
    breakWrap: {
        overflowWrap: "break-word"
    },
    modal: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4, 3),
    },
    modal_800: {
        width: "800px !important"
    },
    footer: {
        position: "fixed",
        left: 64,
        [theme.breakpoints.down('lg')]: {
            left: 0
        },
        backgroundColor: theme.palette.background.dark,
        width: "100%",
        bottom: 0
    },
    a: {
        textDecoration: "underline",
        color: "#000000",
        letterSpacing: "0",
    },
    loading: {
        cursor: "wait"
    },
    error: {
        color: "#f44336"
    },
    listButton: {
        border: 0,
        marginTop: "-8px"
    },
    list: {
        height: "calc(82vh - 170px)"
    },
    detail_items: {
        paddingLeft: 26
    }
}));


const globalTheme = {
    _default,
    defaultFormStyle,
    defaultCards,
    wizard
}
export default globalTheme;
