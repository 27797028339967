import {CANCEL_CONTRACT_REQUEST_API_PATH} from "./constants";
import ApiUtils from "../../../../utils/ApiUtils";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /service_contractsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 解約理由の取得リクエスト
 * @param accessToken
 * @param accountId
 * @param serviceContractId
 * @returns {AxiosPromise}
 */
export function getCancelServiceReasonsAPI(accessToken, accountId, serviceContractId) {
    return new ApiUtils(accessToken).get(
        CANCEL_CONTRACT_REQUEST_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId),
        null,
        null
    )
}

/**
 * 契約解約リクエスト
 * @param accessToken
 * @param accountId
 * @param serviceContracts
 * @param form
 * @returns {AxiosPromise}
 */
export function cancelServiceContractAPI(accessToken, accountId, serviceContracts, form) {
    return new ApiUtils(accessToken).put(
        CANCEL_CONTRACT_REQUEST_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContracts),
        "",
        form,
        null
    )
}
