import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import ServiceChargeList from "../list/ServiceChargeList";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceChargeQuoteSegment = (
    {
        requestId,
        quoteId,
        requestQuoteLoading,
        requestQuote,
        requestDetail,
        t
    }) => {
    const classes = useStyles();
    return (
        <>
            <Box className={clsx(classes.root)} paddingBottom={2}>
                <Typography variant="h4"> {t('requests.detail.quotes.service_charge')}</Typography>
            </Box>
            <ServiceChargeList requestQuote={requestQuote} requestDetail={requestDetail} t={t}/>
        </>
    );
}

export default ServiceChargeQuoteSegment;
