/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

import {call, put} from "redux-saga/effects";
import {
    confirmContractUpdateFailure,
    confirmContractUpdateLoading, confirmContractUpdateSuccess,
    serviceContractContentAllPlansFailure,
    serviceContractContentAllPlansLoading,
    serviceContractContentAllPlansSuccess,
    serviceContractContentLoadFailure,
    serviceContractContentLoading,
    serviceContractContentLoadSuccess
} from "./actions";
import {error_raise} from "../../../root/actions";
import {allPlansRequestAPI, confirmPlansUpdateRequestAPI, serviceContractContentRequestAPI} from "./api";

/**
 * 契約内容照会の取得
 * @param data
 */
export function* serviceContractContentsRequest(data) {
    yield put(serviceContractContentLoading())
    try {
        const requests = yield call(serviceContractContentRequestAPI, data.payload.access_token, data.payload.account_id, data.payload.service_contract_id);
        yield put(serviceContractContentLoadSuccess(requests.data));
    } catch (e) {
        yield put(serviceContractContentLoadFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 *すべてのプランの取得
 * @param data
*/
export function* serviceContractAllPlansRequest(data) {
    yield put(serviceContractContentAllPlansLoading())
    try {
        const requests = yield call(allPlansRequestAPI, data.payload.access_token, data.payload.account_id, data.payload.service_contract_id);
        yield put(serviceContractContentAllPlansSuccess(requests.data));
    } catch (e) {
        yield put(serviceContractContentAllPlansFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ご契約内容更新の問い合わせリクエスト
 * @param data
*/
export function* confirmPlanChangesRequest(data) {
    yield put(confirmContractUpdateLoading())
    try {
        yield call(confirmPlansUpdateRequestAPI, data.payload.accessToken, data.payload.account_id, data.payload.serviceContractId, data.payload.plans, data.payload.inquiry);
        yield put(confirmContractUpdateSuccess());
    } catch (e) {
        yield put(confirmContractUpdateFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
