/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないnotificationsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */

export const TRANSFER_ACCEPT_API_PATH = "/transfer/{AID}/accept"
