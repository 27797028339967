import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているNotificationsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const transferApproveState = {
    transferApproveLoading: false,
    transferApproveError: {},
    transferApproveData: {},

    transferApproveSendLoading: false,
    transferApproveSendError: {},
    isApproveComplete: false
};

export default function transferApprove(state = transferApproveState, action) {
    switch (action.type) {
        case (types.APPROVE_TRANSFER_REQUEST_LOADING):
            return {
                ...state,
                transferApproveLoading: state.transferApproveLoading = true,
                transferApproveError: state.transferApproveError = {},
                transferApproveData: state.transferApproveData = {}
            }
        case (types.APPROVE_TRANSFER_REQUEST_SUCCESS):
            return {
                ...state,
                transferApproveLoading: state.transferApproveLoading = false,
                transferApproveData: state.transferApproveData = action.payload.approveData
            }
        case (types.APPROVE_TRANSFER_REQUEST_FAILED):
            return {
                ...state,
                transferApproveLoading: state.transferApproveLoading = false,
                transferApproveError: state.transferApproveError = action.payload.error
            }
        case (types.SEND_APPROVE_TRANSFER_REQUEST_LOADING):
            return {
                ...state,
                transferApproveSendLoading: state.transferApproveSendLoading = true,
                transferApproveSendError: state.transferApproveSendError = {}
            }
        case (types.SEND_APPROVE_TRANSFER_REQUEST_SUCCESS):
            return {
                ...state,
                transferApproveSendLoading: state.transferApproveSendLoading = false,
                isApproveComplete: state.isApproveComplete = true
            }
        case (types.SEND_APPROVE_TRANSFER_REQUEST_FAILED):
            return {
                ...state,
                transferApproveSendLoading: state.transferApproveSendLoading = false,
                transferApproveSendError: state.transferApproveSendError = action.payload.error
            }
        default:
            return state;
    }
}
