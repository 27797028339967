import actions from "./actions";
/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

/** @var loadNotificationList - お知らせ取得リクエスト  **/
const loadNotificationList = actions.loadNotificationList;

/** @var loadNotificationAllList - 全てのお知らせ取得リクエスト  **/
const loadNotificationAllList = actions.loadNotificationAllList;

const operations = {
    loadNotificationList,
    loadNotificationAllList,
};
export default operations;
