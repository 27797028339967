import React from 'react';
import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import NoticesList from "./list/NoticesList";
import {ConfirmationModal} from "../../../atoms/ConfirmationModal";
import ManagerNoticeModalContainer from "../../../containers/manager/notices/ManagerNoticeModalContainer";
import RestrictSales from "../../../atoms/RestrictSales";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param {Object} error - ページエラーの詳細
 * @param {boolean} reLoad - ダブルロードチェッカー
 * @param {Object} managerNotices - 現在のロードされたお知らせ
 * @param {boolean} managerLoading - ロード中フラグ
 * @param {Object} managerProfile - ログインしたユーザーのプロフィール
 * @param managerNotificationRegisterToggle
 * @param managerIsRegisterView
 * @param managerRegisterNewNotice
 * @param managerUpdateNotice
 * @param activeNotice
 * @param managerViewNoticeDeleteToggle
 * @param managerIsDeleteView
 * @param deleteLoading
 * @param deleteError
 * @param deleteNoticeUUID
 * @param managerDeleteNotice
 * @param managerSearchAccountLoading
 * @param managerSearchAccountResult
 * @param managerNotificationSearchAccount
 * @param managerNotificationChooseAccount
 * @param managerSelectedAccount
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const NoticesView = (
    {
        error,
        reLoad,
        managerNotices,
        managerLoading,
        managerProfile,
        managerNotificationRegisterToggle,
        managerViewNoticeDeleteToggle,
        managerIsDeleteView,
        deleteLoading,
        deleteError,
        deleteNoticeUUID,
        managerDeleteNotice,

        t,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('users.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('notices.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <RestrictSales>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                data-testid="create-button"
                                onClick={() => managerNotificationRegisterToggle()}
                            >
                                {t('notices.add')}
                            </Button>
                        </RestrictSales>
                    </Grid>
                </Grid>
                {/* 作成・編集 モーダルコンポーネント */}
                <ManagerNoticeModalContainer/>
                {/* 削除モーダルコンポーネント  */}
                <ConfirmationModal
                    isOpen={managerIsDeleteView}
                    setClose={managerViewNoticeDeleteToggle}
                    confirmationTitle={t('notices.delete.title')}
                    confirmationDescription={t('notices.delete.description')}
                    buttonText={t('actions.delete')}
                    isLoading={deleteLoading}
                    error={t(deleteError?.translation_error_key)}
                    loadingText={t('actions.sending')}
                    id={deleteNoticeUUID !== null && deleteNoticeUUID.uuid}
                    deleteInformation={t('notices.delete.delete_account_title', {notify: deleteNoticeUUID !== null ? (deleteNoticeUUID.title) : null})}
                    event={managerDeleteNotice}
                    cancelText={t('actions.cancel')}
                />
                <Box mt={3}>
                    {/* ユーザー一覧 */}
                    <NoticesList
                        loginUserID={managerProfile.id}
                        managerLoading={managerLoading}
                        notices={managerNotices}
                        managerNotificationRegisterToggle={managerNotificationRegisterToggle}
                        managerViewNoticeDeleteToggle={managerViewNoticeDeleteToggle}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
}

export default NoticesView;
