import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, makeStyles, Toolbar, Typography} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import {commonStyles} from '../../theme/user/styles/common'
import {ReactComponent as Logo} from "../../../assests/img/logo.svg";
import {NavLink as RouterLink, useLocation} from "react-router-dom";

/**
 * ヘッダースタイル
 */
const useStyles = makeStyles(() => ({
    root: {
        height: 70,
    },
    title: {
        fontSize: 16,
        fontWeight: "Bold"
    },
}));

/**
 * 通常ユーザー側のヘッダーコンポーネント
 * @param {string} className - 親からのクラス名
 * @param {*} t - 翻訳ファイル
 * @param {*} i18n - 翻訳ファイル
 * @param {*} rest - 他の変数
 */
const TopBar = ({
                    className,
                    t,
                    i18n,
                    ...rest
                }) => {
    const classes = useStyles();
    // アプリケーションテーマ
    const styles = commonStyles();

    // URLパスの取得
    const location = useLocation();

    const NavigationMap = new Map([
        ['service_contract', '/service_contracts'],
        ['requests', '/requests'],
        ['addresses', '/addresses'],
        ['users', '/users'],
        ['contact', '/contact']
    ]);

    NavigationMap.forEach((value, key, map) => {
        if (location.pathname.includes(key)) {
            NavigationMap.set(key, location.pathname)
        }
    })

    return (
        <AppBar
            elevation={0}
            color={"inherit"}
            className={styles.navHeaderBackground}
        >
            <Toolbar className={classes.root}>
                <RouterLink to={"/"}>
                    <Logo style={{marginRight: 10}}>

                    </Logo>
                </RouterLink>
                <Typography component="h1" variant='h5' color="inherit" noWrap className={classes.title}>
                    {t('root.company_name')}　{t('root.application')}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
};

export default withTranslation('common')(TopBar);
