import {call, put} from "redux-saga/effects";
import {managerAddNotice, managerCallNotices, managerDeleteNotice, managerUpdateNotice} from "./api";
import {
    managerDeleteNoticeFailure,
    managerDeleteNoticeLoading,
    managerDeleteNoticeSuccess,
    managerFailedNoticeList,
    managerLoadedNoticeList,
    managerLoadingNoticeList,
    managerLoadingRegisterNotice,
    managerLoadNoticeList,
    managerNotificationSearchAccountFailure,
    managerNotificationSearchAccountLoading,
    managerNotificationSearchAccountSuccess,
    managerRegisterNewNoticeFailure,
    managerRegisterNewNoticeSuccess
} from "./actions";
import {error_raise} from "../../root/actions";
import {managerCallAccounts} from "../managerAccounts/api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * アカウント取得リクエスト
 * @param data
 */
export function* managerSelectNoticeList(data) {
    yield put(managerLoadingNoticeList())
    try {
        const accounts = yield call(managerCallNotices, data.payload.access_token, null);
        yield put(managerLoadedNoticeList(accounts.data));
    } catch (e) {
        yield put(managerFailedNoticeList(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 新規お知らせ
 * @param data
 */
export function* managerAddNoticeSaga(data) {
    yield put(managerLoadingRegisterNotice())
    try {
        yield call(managerAddNotice, data.payload.access_token, data.payload.notice)
        yield put(managerRegisterNewNoticeSuccess());
        yield put(managerLoadNoticeList(data.payload.access_token))
    } catch (e) {
        yield put(managerRegisterNewNoticeFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お知らせ更新
 * @param data
 * @returns
 */
export function* managerUpdateNoticeSaga(data) {
    yield put(managerLoadingRegisterNotice())
    try {
        yield call(managerUpdateNotice, data.payload.notice, data.payload.new_notice, data.payload.access_token)
        yield put(managerRegisterNewNoticeSuccess());
        yield put(managerLoadNoticeList(data.payload.access_token))
    } catch (e) {
        yield put(managerRegisterNewNoticeFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お知らせ削除リクエスト
 *
 * @param accountData - お知らせUUIDとauth0アクセストークン
 */
export function* managerDeleteNotify(accountData) {
    yield put(managerDeleteNoticeLoading())
    try {
        yield call(managerDeleteNotice, accountData.payload.notice, accountData.payload.access_token)
        yield put(managerDeleteNoticeSuccess())
        yield put(managerLoadNoticeList(accountData.payload.access_token))
    } catch (e) {
        yield put(managerDeleteNoticeFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お知らせのアカウント検索
 *
 * @param accountData - お知らせUUIDとauth0アクセストークン
 */
export function* managerNotificationsSearchAccount(accountData) {
    yield put(managerNotificationSearchAccountLoading())
    try {
        const accountSearchResult = yield call(managerCallAccounts, accountData.payload.access_token, accountData.payload.params)
        yield put(managerNotificationSearchAccountSuccess(accountSearchResult.data?.accounts))
    } catch (e) {
        yield put(managerNotificationSearchAccountFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}


