/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const REQUEST_USERS = 'REQUEST_USERS'
export const REQUEST_USERS_LOADING = 'REQUEST_USERS_LOADING'
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS'
export const REQUEST_USERS_FAILURE = 'REQUEST_USERS_FAILURE'
export const USER_CHANGE_PAGE = 'MANAGER_CHANGE_PAGE'

export const TOGGLE_REGISTER = 'TOGGLE_REGISTER'

export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER'
export const ADD_USER_LOADING = 'ADD_USER_LOADING';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_LOADING = 'SEARCH_USER_LOADING';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const DELETE_USER_TOGGLE = "DELETE_USER_TOGGLE"
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_LOADING = 'DELETE_USER_LOADING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const AUTH0_UNLOCK_USER = "AUTH0_UNLOCK_USER";
export const AUTH0_UNLOCK_USER_LOADING = "AUTH0_UNLOCK_USER_LOADING";
export const AUTH0_UNLOCK_USER_SUCCESS = "AUTH0_UNLOCK_USER_SUCCESS";
export const AUTH0_UNLOCK_USER_FAILED = "AUTH0_UNLOCK_USER_FAILED";
export const AUTH0_UNLOCK_USER_CLOSE_MODAL = "AUTH0_UNLOCK_USER_CLOSE_MODAL";

export const AUTH0_RESEND_VERIFY_EMAIL = "AUTH0_RESEND_VERIFY_EMAIL";
export const AUTH0_RESEND_VERIFY_EMAIL_LOADING = "AUTH0_RESEND_VERIFY_EMAIL_LOADING";
export const AUTH0_RESEND_VERIFY_EMAIL_SUCCESS = "AUTH0_RESEND_VERIFY_EMAIL_SUCCESS";
export const AUTH0_RESEND_VERIFY_EMAIL_FAILED = "AUTH0_RESEND_VERIFY_EMAIL_FAILED";
export const AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL = "AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL";

const types = {
    REQUEST_USERS,
    REQUEST_USERS_LOADING,
    REQUEST_USERS_SUCCESS,
    REQUEST_USERS_FAILURE,
    USER_CHANGE_PAGE,

    TOGGLE_REGISTER,

    ADD_USER,
    UPDATE_USER,
    ADD_USER_LOADING,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,

    SEARCH_USER_REQUEST,
    SEARCH_USER_LOADING,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,

    DELETE_USER_TOGGLE,
    DELETE_USER,
    DELETE_USER_LOADING,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,

    AUTH0_UNLOCK_USER,
    AUTH0_UNLOCK_USER_LOADING,
    AUTH0_UNLOCK_USER_SUCCESS,
    AUTH0_UNLOCK_USER_FAILED,
    AUTH0_UNLOCK_USER_CLOSE_MODAL,

    AUTH0_RESEND_VERIFY_EMAIL,
    AUTH0_RESEND_VERIFY_EMAIL_LOADING,
    AUTH0_RESEND_VERIFY_EMAIL_SUCCESS,
    AUTH0_RESEND_VERIFY_EMAIL_FAILED,
    AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL
};

export default types;
