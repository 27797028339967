/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */
const createContactForm = {
    message: null
}

const forms = {
    createContactForm
}

export default forms;
