import {takeLeading} from "redux-saga/effects";
import {default as types} from "./types";
import {selectAllNotificationList, selectNotificationList} from "./sagas";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchNotifications() {
    yield takeLeading(types.REQUEST_NOTIFICATIONS, selectNotificationList);
    yield takeLeading(types.REQUEST_ALL_NOTIFICATIONS, selectAllNotificationList)
}
