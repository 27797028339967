/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（users）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const noticeWatcherSagas = Object.values(watchersSagas);
export {default as managerNoticeSelectors} from "./selectors";
export {default as managerNoticeOperations} from "./operations";
export {default as managerNoticeTypes} from "./types";
export {default as managerNoticeForms} from "./forms"

export {managerNoticeState, default as managerNoticeReducer} from './reducers';
