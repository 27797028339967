import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, Typography} from '@material-ui/core';
import Page from "../../Page";
import {Link} from "react-router-dom";
import ServiceContractList from "./list/ServiceContractList";
import managerUrlLinks from "../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import globalTheme from "../../../../theme/globalTheme";

/**
 * アカウントのユーザー一覧
 * @param {Object} classes
 * @param {boolean} managerDetailListLoading - ロード中かどうか
 * @param managerDetailAccountData
 * @param managerLoginEvent
 * @param managerDetailListError
 * @param managerDetailListData
 * @param accountId
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountServiceContractsView = (
    {
        classes,
        managerDetailListLoading,
        managerDetailAccountData,
        managerLoginEvent,
        managerDetailListError,
        managerDetailListData,
        accountId,
        t
    }) => {
    const commonTheme = globalTheme._default();
    return (
        <Page
            className={classes.root}
            title={t('accounts.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('accounts.service_contracts.title', {account_name: managerDetailAccountData.name})}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} color={"#000000"} aria-label="breadcrumb" style={{display: 'inline-block'}}>
                            <Link to={managerUrlLinks.navigation.accounts.index.href}>
                                <Typography color="textPrimary">{t('accounts.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={managerUrlLinks.navigation.accounts.index.sub_links.service_contracts.href.replace('{UID}', accountId)}>
                                <Typography
                                    color="textPrimary">{t('accounts.service_contracts.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                        <Button
                            style={{marginLeft: 10, marginTop: 2}}
                            variant={"text"}
                            size={"small"}
                            className={commonTheme.listButton}
                            onClick={() => managerLoginEvent(managerDetailAccountData.uuid, managerDetailAccountData.name,
                                () => {
                                    window.open('/', '_blank');
                                })}
                        >
                            <ExitToAppIcon
                                style={{fontSize: 12, marginRight: 3, marginTop: "-3px"}}/>{t('actions.login')}
                        </Button>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <ServiceContractList
                        serviceContracts={managerDetailListData}
                        loading={managerDetailListLoading}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ManagerAccountServiceContractsView;
